import type { PayloadAction } from "@reduxjs/toolkit";
import { createAppSlice } from "../../app/createAppSlice";
import type { AppThunk } from "../../app/store";
import { createCompany, updateCompany as updateCompanyData, getCompanySpot } from "api/companyApi";
import { getUser, userLogin, userLogout, userRegister } from "api/userApi";
import { setCookie } from "utils/setCookie";
import {getPaymentMethod} from "../../api/paymentsApi";

interface CompanyData {
  company_id?: number;
  company_name?: string;
  name?: string,
  email?: string,
  spot_id?: number;
  spot_address?: string;
  google_map_link?: string;
  spot_qr_code?: string;
  spot_slug?: string | null;
  lang?: string;
  vat_number?: number;
  ngo?: string;
  project?: string;
  review_platform?: string;
}

interface CompanyReview {
  created_at?: string;
  user_name?: string;
  user_avatar?: string;
  rating?: number;
  comment?: string;
}

interface CompanyReviews {
  reviews?: CompanyReview[];
  average_rating?: number;
  company_name?: string; // TODO - remove this
  company_id?: number; // TODO - remove this
  current_page?: number;
  per_page?: number;
  total?: number;
  spot_link?: string; // TODO - remove this
}

export interface CounterSliceState {
  isAuthenticated: boolean;
  loginError?: string;
  isCompletedOnboarding?: boolean;
  access_token?: string | null;
  company?: CompanyData | null;
  companyReviews?: CompanyReviews | null;
  isLoading?: boolean;
}

const initialState: CounterSliceState = {
  isAuthenticated: false,
  isCompletedOnboarding: false,
  company: {
    company_id: 0,
    company_name: "",
    name: "",
    email: "",
    spot_id: 0,
    spot_address: "",
    google_map_link: "",
    spot_qr_code: "",
    spot_slug: "",
    lang: "",
    vat_number: undefined,
    ngo: "",
    project: "",
    review_platform: "",
  },
  companyReviews: {
    reviews: [],
    average_rating: 0,
    company_name: "",
    company_id: 0,
    current_page: 0,
    per_page: 0,
    total: 0,
    spot_link: "",
  },
  isLoading: false,
};

export const companySlice = createAppSlice({
  name: "company",
  initialState,
  reducers: {
    setCompany: (state, action: PayloadAction<CompanyData>) => {
      state.company = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setAuth: (state, action: PayloadAction<CounterSliceState>) => {
      state.isAuthenticated = action.payload.isAuthenticated ?? true;
      state.access_token = action.payload.access_token;
      state.company = { ...state.company, ...action.payload.company };
    },
    setLogout: (state) => {
      state.isAuthenticated = false;
      state.access_token = null;
      state.company = null;
    },
    setIsCompletedOnboarding: (state, action: PayloadAction<boolean>) => {
      state.isCompletedOnboarding = action.payload;
    },
    setAuthError: (state, action: PayloadAction<string>) => {
      state.loginError = action.payload;
    },
  },
  selectors: {
    selectCompany: (state: CounterSliceState) => state,
    selectAuth: (state: CounterSliceState) => state.isAuthenticated,
    selectIsCompletedOnboarding: (state: CounterSliceState) => state.isCompletedOnboarding,
    selectIsLoading: (state: CounterSliceState) => state.isLoading,
  },
});

export const { setCompany, setLogout, setLoading } = companySlice.actions;

export const { selectCompany, selectAuth, selectIsCompletedOnboarding, selectIsLoading } = companySlice.selectors;

export const fetchCompany = (slug?: string): AppThunk =>
  async (dispatch) => {
    if (slug) {
      dispatch(setLoading(true));
      const company = await getCompanySpot(slug);
      dispatch(setCompany(company));
    }
};

export const updateCompany = (companyData: CompanyData, companyId: number): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    const company = await updateCompanyData(companyData, companyId);
    dispatch(setCompany(company));    
};

export const fetchUser = (): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    dispatch(setLoading(true));
    const user = await getUser();
    dispatch(setCompany(user));
    dispatch(setAuth(user));
    const isCompletedOnboarding = user.is_accessible;
    dispatch(setIsCompletedOnboarding(isCompletedOnboarding));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const createCompanyThunk =
  (companyData: any): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const company = await createCompany(companyData);
      dispatch(setCompany(company));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

export const { setAuth, setAuthError, setIsCompletedOnboarding } = companySlice.actions;

export const loginAsync =
  (email: string, password: string): AppThunk<any> =>
  async (dispatch) => {
    try {
      const response = await userLogin(email, password);
      dispatch(setAuth(response));
      dispatch(setCompany(response));
      const isCompletedOnboarding = response.is_accessible;
      dispatch(setIsCompletedOnboarding(isCompletedOnboarding));
      return response;
    } catch (error) {
      console.error(error);
      dispatch(setLogout());
      dispatch(setAuthError("Invalid email or password"));
      return Promise.reject(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

export const logoutAsync = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    await userLogout();
    dispatch(setLogout());
    setCookie("accessToken", "", 1);
    setCookie("authData", "", 1);    
  } catch (error) {
    console.error(error);
  } finally {
    setCookie("accessToken", "", 1);
    dispatch(setLogout());    
    dispatch(setLoading(false));
    localStorage.clear();
  }
};

export const registerAsync = (email: string, lang: string, name: string, role_id: number): AppThunk<any> =>
  async (dispatch) => {
    try {
      const response = await userRegister(email, lang, name, role_id);
      dispatch(setAuth(response));
      return response;
    } catch (error: any) {
      console.error(error);
      dispatch(setLogout());
      return Promise.reject(error);
    }
  };
