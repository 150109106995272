import type { PayloadAction } from "@reduxjs/toolkit";
import { createAppSlice } from "../../app/createAppSlice";
import type { AppThunk } from "../../app/store";
import { getCompanySpot, updateCompanySpot as updateCompanySpotData } from "api/companyApi";

interface CompanySpotData {
    company_id: number;
    company_name: string;
    name?: string,
    email?: string,
    spot_id: number;
    spot_name: string;
    is_activated: boolean,
    address: string;
    google_map_link: string;
    qr_code: string;
    spot_slug: string | null;
    lang?: string;
    vat_number?: string;
    ngo?: string;
    project?: string;
    review_platform?: string;
    tariff_id?: number;
}

export interface UpdateCompanySpotData {
    is_activated?: boolean,
    company_name?: string;
    vat_number?: string;
    slug?: string;
    spot_name?: string;
}

export interface CompanySpotState {
    isLoading: boolean;
    data: CompanySpotData | null;
    error: string;
}

export const initialState: CompanySpotState = {
    isLoading: false,
    data: null,
    error: ''
};

export const companySpotSlice = createAppSlice({
  name: "companySpot",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
        state.isLoading = action.payload;
    },
    setCompanySpot: (state, action: PayloadAction<CompanySpotData>) => {
      state.data = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
  selectors: {
    selectCompanySpotState: (state: CompanySpotState) => state,
  },
});

export const { setLoading, setCompanySpot, setError } = companySpotSlice.actions;
export const { selectCompanySpotState } = companySpotSlice.selectors;

export const fetchCompanySpot = (slug: string): AppThunk => async (dispatch) => {
  try {
    if (slug) {
      dispatch(setLoading(true));
      const companySpot = await getCompanySpot(slug);
      dispatch(setCompanySpot(companySpot?.data));
      dispatch(setLoading(false));
    }
  } catch (err: any) {
      if (err?.response?.data?.message) {
        dispatch(setError(err.response.data.message || ''));
    }
  }
};

export const updateCompanySpot = (companySpotData: UpdateCompanySpotData, slug: string): AppThunk => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    await updateCompanySpotData(companySpotData, slug);
    const currentSlug = companySpotData.slug || slug;
    setTimeout(async () => {
      dispatch(fetchCompanySpot(currentSlug))
      dispatch(setLoading(false));
    }, 200)
  } catch (err: any) {
    if (err?.response?.data?.message) {
      dispatch(setError(err.response.data.message || ''));
    }
  }
};
