import { apiInstance } from "./api";

export const pickPlan = async (id: number, success_url: string, cancel_url: string) => {
  const response = await apiInstance().post(
    `/api/subscribe`,
    { product_id: id, success_url, cancel_url},
    {
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
    }
  );
  return response.data;
};

export const getPaymentMethod = async () => {
  const response = await apiInstance().get(`/api/payment-method`);
  return response.data;
};
