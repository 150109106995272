import React from "react";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import styles from "../BusinessForgot/BusinessSignIn.module.scss";
import Button from "../../../../components/uiKit/Button/Button";
import SelectLanguage from "../../../../components/businessAuth/SelectLanguage";
import {useTranslation} from "react-i18next";
import {apiInstance} from "../../../../api/api";
import {toast} from "react-toastify";

function BusinessResetPassword () {
  const logo = require("assets/logo.png");
  const { t } = useTranslation();
  const [userEmail, setUserEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [password_confirmation, setPasswordConfirmation] = React.useState("");


  const [backendError, setBackendError] = React.useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const token = searchParams.get("token");

  if (!token) {
    navigate("/business/signin");
  }

  const handleEmailChange = (event: any) => {
    setBackendError("");
    setUserEmail(event.target.value);
  };

  const handlePasswordChange = (event: any) => {
    setBackendError("");
    setPassword(event.target.value);
  };

  const handlePasswordConfirmationChange = (event: any) => {
    setBackendError("");
    setPasswordConfirmation(event.target.value);
    if (password !== event.target.value) {
      setBackendError("Passwords do not match")
    }
  };

  const onSubmit = (e: any) => {
    apiInstance().post("/api/auth/password/reset/", {email: userEmail,
      password: password,
      password_confirmation:
      password_confirmation,
      token: token}).then(() => {
        toast(t("Password changed successfully"), {
          type: "success",
        })
        navigate("/business/signin")}).catch(() => {
      setBackendError(t("Your password reset link has expired or is invalid, to request a new password reset email, please click the Forgot your password link below"))
    })
  }

  return (
    <div className={styles.signRedirect}>
      <h1>{t("Change password")}</h1>
      <img src={logo} alt="" style={{maxWidth: "100px"}}/>

      <input
        type="email"
        placeholder={t('Email address')}
        name="email"
        value={userEmail}
        onChange={handleEmailChange}
      />
      <input
        type="password"
        placeholder={t('New Password')}
        name="password"
        value={password}
        onChange={handlePasswordChange}
      />
      <input
        type={"password"}
        placeholder={t("Confirm Password")}
        name="password_confirmation"
        value={password_confirmation}
        onChange={handlePasswordConfirmationChange}
        />
      {backendError && <p style={{color: "red", textAlign: "center"}}>{backendError}</p>}

      <div className={styles.bottomContainer}>
        <Button onClick={onSubmit}>
          {t("Change Password")}
        </Button>
        <div className="" style={{padding: "10px", textAlign: "center"}}>
          <Link to='/terms' className="grey-link">{t("By continuing you agree to our Terms and Privacy Policy.")}</Link>
        </div>
      </div>
      <SelectLanguage/>
    </div>
  );
}

export default BusinessResetPassword;