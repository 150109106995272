import {useQuery} from "@tanstack/react-query";
import queryKeys from "../queryKeys";
import {apiInstance} from "../../api/api";
import {InvoiceList} from "../../types/Invoice";

interface IUseInvoiceById {
    invoice_id?: string | null;
    company_id?: string | null;
    page?: number;
}

export const useInvoiceById = (props: IUseInvoiceById) => {
    const {invoice_id, company_id,  page = 1} = props;

    return useQuery({
        queryKey: [queryKeys.current_invoice, invoice_id, company_id, page],
        queryFn: async () => {
            const params = {
                page,
                spot_id: invoice_id,
                company_id
            }

            if (!invoice_id || company_id) {
                delete params.spot_id;
            }
            const response = await apiInstance().get<InvoiceList>(`api/invoices`, {
                params
            });

            return response.data;
        },
    })
}
