import React, {useContext, useEffect, useState} from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import BusinessDashboard from "pages/business/dashboard/BusinessDashboard";
import BusinessOnboarding from "pages/business/onboarding/BusinessOnboarding";
import BusinessSignIn from "pages/business/auth/BusinessSignIn/BusinessSignIn";
import BusinessSignUp from "pages/business/auth/BusinessSignUp/BusinessSignUp";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  fetchUser,
  selectAuth,
  selectIsCompletedOnboarding,
  selectIsLoading,
} from "features/business/companySlice";
import getCookie from "utils/getCookie";
import { AppContext } from "service/AppContext";
import BusinessForgot from "../pages/business/auth/BusinessForgot/BusinessForgot";
import BusinessResetPassword from "../pages/business/auth/BusinessResetPassword/BusinessResetPassword";
import ReviewTable from "../pages/business/dashboard/components/ReviewTable/ReviewTable";
import PrintTemplates from "../pages/business/dashboard/components/printTemplates/PrintTemplates";
import SettingsTab from "../pages/business/dashboard/components/SettingsTab/SettingsTab";
import BillingPlanContainer from "../pages/business/dashboard/components/BillingPlanContainer";
import {app_urls} from "./urls";
import EmailSignature from "../pages/business/dashboard/components/emailSignature/EmailSignature";
import SpotsHeroboard from "../pages/business/dashboard/components/SpotsHeroboard";
import Billing from "../pages/business/dashboard/components/Billing/Billing";
import CompaniesHeroboard from "../pages/business/dashboard/components/CompaniesHeroboard";
import BusinessCreatePasswordPage from "../pages/business/auth/BusinessCreatePassword";

const BusinessRoutes = () => {
  const isAuthenticated = useAppSelector(selectAuth);
  const isCompletedOnboarding = useAppSelector(selectIsCompletedOnboarding);
  const isLoading = useAppSelector(selectIsLoading);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { loadUser } = useContext<any>(AppContext);
  const [init, setInit] = useState(false);

  if (!isAuthenticated) {
    sessionStorage.setItem("preAuthRoute", location.pathname);
  }

  useEffect( () => {
    let accessToken = getCookie("accessToken");
    if (accessToken) {
      dispatch(fetchUser());

      try {
        loadUser();
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          console.log("getPaymentMethod error 401");
          navigate("/business/signin");
        }
      }
    }

    if (!init) {
      setInit(true)
    }
  }, []);
  // TODO: find a better way to handle this

  if (isLoading || !init) {
    return (
      <div>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
         >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      );
  }

  if (!isAuthenticated) {
    return (
      <Routes>
        <Route path="/signin" element={<BusinessSignIn />} />
        <Route path="/signup" element={<BusinessSignUp />} />
        <Route path="/forgot-password" element={<BusinessForgot />} />
        <Route path="/reset-password" element={<BusinessResetPassword />} />
        <Route path="/create-password" element={<BusinessCreatePasswordPage />} />
        <Route path="*" element={<Navigate replace to="signin" />} />
      </Routes>
    );
  } else {
    if (!isCompletedOnboarding) {
      return (
        <Routes>
          <Route path="/onboarding" element={<BusinessOnboarding />} />
          <Route path="*" element={<Navigate replace to="onboarding" />} />
        </Routes>
      );
    }
    return (
      <Routes>
        <Route path={app_urls.business.dashboard} element={<BusinessDashboard />} >
          <Route path={app_urls.business.companies} element={<CompaniesHeroboard />} />
          <Route path={app_urls.business.spots()} element={<SpotsHeroboard/>}/>
          <Route path={app_urls.business.billings()} element={<Billing/>}/>
          <Route path={app_urls.business.settings()} element={<SettingsTab/>}/>
          <Route path={app_urls.business.billing()} element={<BillingPlanContainer/>}/>
          <Route path={app_urls.business.reviews()}  element={<ReviewTable/>}/>
          <Route path={app_urls.business.print_templates()} element={<PrintTemplates/>}/>
          <Route path={app_urls.business.email_signature()} element={<EmailSignature/>} />
          <Route path="/dashboard" element={<Navigate replace to={'/business' + app_urls.business.companies} />} />
        </Route>
        <Route path="*" element={<Navigate replace to="dashboard" />} />
      </Routes>
    );
  }
};

export default BusinessRoutes;
