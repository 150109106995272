import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter as Router} from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import App from "./App";
import { AppProvider } from "./service/AppContext";
import i18n from "./i18n";
import { ClientProvider } from "./service/ClientContext";
import { store } from "./app/store";
import * as Sentry from "@sentry/react";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

Sentry.init({
  dsn: "https://e265cb5330aef209a9222f41715fed32@o1114510.ingest.us.sentry.io/4506976760430592",
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration()
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", "https://reviro.tech.anroit.com", "https://app.reviro.co"],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});


const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <I18nextProvider i18n={i18n}>
      <React.StrictMode>
        <Provider store={store}>
          <Router>
            <AppProvider>
              <ClientProvider>
                <App />
              </ClientProvider>
            </AppProvider>
          </Router>
        </Provider>
      </React.StrictMode>
    </I18nextProvider>
);
