import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import InputAdornment from "@mui/material/InputAdornment";
import { RegData } from "models";
import { createCompany } from "api/companyApi";
import CustomTextField from "components/CustomTextField";

const CreateForestForm = ({ progress, setProgress, setStage, setStep }: any) => {
  const { t } = useTranslation();
  const regData: RegData | any = JSON.parse(localStorage.getItem("regData") || "{}");
  const actualSpotSlug = regData?.spot_slug || regData?.company_name
  .replace(/[^a-zA-Z\s]/g, "")
  .replaceAll(" ", "_")
  .toLowerCase()

  const [spotName, setSpotName] = useState<string | null>(regData?.company_name);
  const [spotSlug, setSpotSlug] = useState<string>(actualSpotSlug);

  const icon = require("assets/gicon-color.png");
  const [backendError, setBackendError] = useState("");
  const onSubmit = async () => {
    setBackendError("");
    setProgress({
      ...progress,
      spot_name: spotName,
      spot_slug: spotSlug,
    });
    setStage("Plan & billing");
    localStorage.setItem(
      "regData",
      JSON.stringify({
        ...progress,
        spot_name: spotName,
        spot_slug: spotSlug,
      })
    );
    try {
      await createCompany({
        ...progress,
        spot_name: spotName,
        spot_slug: spotSlug,
      });
      setStep(5);
    } catch (error) {
      console.error(error);
      setBackendError("An account with current link has been already registered. Please choose another one.");
    }
    
  };

  return (
    <div className="goals-wrapper">
      <h3 className="text-center fw-bold">
        {t("Create your Reviro business profile")}
      </h3>
      <div className="p-2 pt-0 pb-0 d-flex align-items-center mt-2">
        <img src={icon} alt="" className="pb-3 pe-2" />
        <div>
          <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
            {regData?.company_name}
          </p>
          <p className="" style={{ fontSize: "12px" }}>
            {regData?.spot_address}
          </p>
        </div>
      </div>
      <p className="pt-2">{t("Your business name & address:")}</p>
      <div className="goals-list ps-0 pe-0">
        <input
          type="text"
          className="select-search"
          autoFocus
          onChange={(e) => setSpotName(e.target.value)}
          placeholder="Forest Name"
          defaultValue={regData?.company_name}
        />
      </div>
      <div className="goals-list ps-0 pe-0">
        <CustomTextField
          id="spot-slug"
          className="select-search"
          InputProps={{
            startAdornment: <InputAdornment position="end">app.reviro.co/</InputAdornment>,
          }}
          fullWidth
          onChange={(e) => {
            setBackendError("");
            setSpotSlug(e.target.value);            
          }}
          defaultValue={spotSlug}
        />
      </div>
      {backendError && <p style={{ color: 'red', textAlign: 'center' }}>{backendError}</p>}
      <div style={{ padding: "0 20px", width: "600px" }} className="text-center">
        <button
          onClick={onSubmit}
          className={spotName && spotSlug ? "submit-review active-btn-green" : "submit-review"}
          style={{ width: "368px" }}
        >
          {t("Save and Continue")}
        </button>
      </div>
    </div>
  );
};

export default CreateForestForm;
