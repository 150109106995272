import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { url } from "service/config";
import Button from "components/uiKit/Button/Button";
import { AppContext } from "service/AppContext";
import { registerAsync } from "features/business/companySlice";
import { useAppDispatch } from "app/hooks";

import styles from './BusinessSignUp.module.scss'
import SelectLanguage from "../../../../components/businessAuth/SelectLanguage";



function BusinessSignUp() {
  const { t } = useTranslation();
  const { language } = useContext<any>(AppContext);
  const logo = require("assets/logo.png");
  const googleIcon = require("assets/google-icon.png");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [backendError, setBackendError] = useState("");
  const [loginUrl, setLoginUrl] = useState<string | null>(null);
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");

  const handleEmailChange = (event: any) => {
    setUserEmail(event.target.value);
  };

  // localStorage.setItem("role", "business");

  useEffect(() => {
    fetch(`${url}/api/auth/redirect`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong!");
      })
      .then((data) => {
        setLoginUrl(data.data.url);
          localStorage.removeItem('review')
          localStorage.setItem('role', '2')
      })
      .catch((error) => console.error(error));
  }, []);

  const onSubmit = (e: any) => {
    dispatch(registerAsync(userEmail, language, userName, 2))
      .then((data: any) => {
        window.location.href = "/business/onboarding";
      })
      .catch((error: any) => {
        console.error(error);
        setBackendError(error.message);
      });
  };

  return (
    <div className={styles.signRedirect}>
      <h1>{t("Welcome to Reviro")}</h1>
      <img src={logo} alt="" style={{ maxWidth: "100px" }} />

      {loginUrl && (
        <a className={styles.googleSign} href={loginUrl}>
          <img src={googleIcon} alt="" />
          <span>{t("Sign up with Google")}</span>
        </a>
      )}
      <p>{t("Or")}</p>
      <input        
        type="text"
        placeholder={t('Full Name')}
        name="name"
        value={userName}
        onChange={(e: any) => setUserName(e.target.value)}
      />
      <input        
        type="email"
        placeholder={t('Email address')}
        name="email"
        value={userEmail}
        onChange={handleEmailChange}
      />
      {backendError && <p style={{ color: "red", textAlign: "center" }}>{backendError}</p>}
      <div className={styles.bottomContainer}>
        <Button onClick={onSubmit}>
          {t("Sign Up")}
        </Button>

        <div className="" style={{ padding: "10px", textAlign: "center" }}>
          <Link to='/terms' className="grey-link">{t("By continuing you agree to our Terms and Privacy Policy.")}</Link>          
        </div>
        <p>
          {t("Already have an account?")}
          &nbsp;
          <Link to='/business/signin'>{t("Sign in")}</Link>          
        </p>
        <SelectLanguage/>
      </div>
    </div>
  );
}

export default BusinessSignUp;
