import {useQuery} from "@tanstack/react-query";
import queryKeys from "../queryKeys";
import {apiInstance} from "../../api/api";
import {Spot} from "../../types/Spot";

export const useSpotById = (spot_id?: string) => {
    const { data, isLoading, isError } = useQuery({
        queryKey: [queryKeys.current_spot, spot_id],
        queryFn: async () => {
            const response = await apiInstance().get<{
                data: Spot
            }>(`api/spots/${spot_id}`);

            return response.data;
        },
        enabled: !!spot_id
    })

    return { data, isLoading, isError }
}