import {useQuery} from "@tanstack/react-query";
import queryKeys from "../queryKeys";
import {apiInstance} from "../../api/api";
import {ReviewListResponse} from "../../types/Reviews";

export const useReviewsBySpot = (spot_id?: string, page?: number) => {
    return useQuery({
        queryKey: [queryKeys.reviews, spot_id, page],
        queryFn: async () => {
            const response = await apiInstance().get<ReviewListResponse>(`api/business/review/${spot_id}`, {
                params: {
                    page
                }
            });

            return response.data;
        },
        enabled: !!spot_id
    })
}