import styles from "./LandingClientNew.module.scss";
import BoySvg from "../../../components/svg/Boy";
import GirlSvg from "../../../components/svg/Girl";
import React from "react";
import {useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";
import {Container} from "@mui/material";

type CompanyNotFoundProps = {
  animate: boolean
  glass: string
  treeWithGlassImg: string
  leftMessageClassName: string
  leftMsg: string
  isMobile: boolean
  rightMessageClassName: string
  rightMsg: string
}

function CompanyNotFound (props: CompanyNotFoundProps) {
  const {t} = useTranslation()
  const {
    animate,
    glass,
    treeWithGlassImg,
    leftMessageClassName,
    leftMsg,
    isMobile,
    rightMessageClassName,
    rightMsg } = props

  return <>
    <div className={styles.landingPageTopBlock}>
      <div className={styles.landingPageTop}>
        <h4 className={styles.landingPageTitle}>
          {/*<span className='green-text'>{404}</span> <br/>*/}
          <Container>
            <Typography variant={"h4"}>
              {t("Thanks for supporting our tree-planting initiative! Unfortunately, our current project reached it’s full capacity and currently we are actively searching for new ones. Stay tuned!")}
            </Typography>
          </Container>
        </h4>
      </div>
    </div>
    <div className={styles.landingPageBottom}>
      <div className={styles.landingPageTreeWithChildren}>
        <div className={styles.treeBlock}>
          <div className={styles.treeImgBlock}>
            <img className={animate ? styles.treeImgAnimate : styles.treeImg}
                 src={treeWithGlassImg}
                 alt="tree"/>
            <img className={styles.treeGlassImg} src={glass} alt="glass"/>
          </div>
          <div className={styles.children}>
            <div className={styles.landingPageBoyWrapper}>
              <img
                className={leftMessageClassName}
                src={leftMsg}
                alt="left-message"
              />
              <BoySvg
                id=''
                className={
                  animate
                    ? styles.landingPageBoy_Revers + ' ' + styles.landingPageBoy
                    : styles.landingPageBoy_SlideIn + ' ' + styles.landingPageBoy
                }
                width={isMobile ? 83 : 138}
                height={isMobile ? 90 : 151}
              />
            </div>
            <div className={styles.landingPageGirlWrapper}>
              <img
                className={rightMessageClassName}
                src={rightMsg}
                alt="right-message"
              />
              <GirlSvg
                id=''
                className={
                  animate
                    ? styles.landingPageGirl_Revers + ' ' + styles.landingPageGirl
                    : styles.landingPageGirl_SlideIn + ' ' + styles.landingPageGirl
                }
                width={isMobile ? 55 : 92}
                height={isMobile ? 91 : 154}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
}

export default CompanyNotFound;