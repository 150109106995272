import {useInfiniteQuery} from "@tanstack/react-query";
import {apiInstance} from "../../api/api";
import {SpotResponse} from "../../types/Spot";
import queryKeys from "../queryKeys";

export const useInfiniteSpotList = () => {
    return useInfiniteQuery({
        queryKey: [queryKeys.spots_infinity],
        queryFn: async ({pageParam }) => {
            const response = await apiInstance().get<SpotResponse>("api/spots", {
                params: {
                    page: pageParam
                }
            });
            return response.data;
        },
        initialPageParam: 1,
        getNextPageParam: (lastPage, pages) => {
            if (lastPage.meta.current_page < lastPage.meta.last_page) {
                return lastPage.meta.current_page + 1;
            }
            return undefined;
        }
    });
}