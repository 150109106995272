import { apiInstance } from "./api";

export const getTemplatesList = async () => {
  const response = await apiInstance().get(`/api/template/`);
  return response.data;
};

export const getTemplate = async (download_link: string) => {
  const response = await apiInstance()
    .get(download_link, {
      // headers: {
      //   "accept": "application/pdf",
      //   'Content-Type': 'application/pdf',
      // },
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `template.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  return response;
};
