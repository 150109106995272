import styles from "./LandingClientNew.module.scss";
import PoorIconSvg from "../../../components/svg/PoorIcon";
import GoodIconSvg from "../../../components/svg/GoodIcon";
import {Link, useNavigate} from "react-router-dom";
import {app_urls} from "../../../routes/urls";
import React from "react";
import {getCompanySpot, sendSpotId} from "../../../api/companyApi";
import {useTranslation} from "react-i18next";
import {useMediaQuery} from "react-responsive";

type LandingReviewButtonsProps = {
  setFormShown: any;
  authData: any;
  navLink: any;
  slug: string;
  showPrivacyPolicy?: boolean;
}

function LandingReviewButtons({setFormShown, authData, navLink, slug, showPrivacyPolicy = true}: LandingReviewButtonsProps) {
  const navigate = useNavigate()
  const {t, i18n} = useTranslation();
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const handeClickGood = () => {
    localStorage.setItem('review', 'positive');
    slug && localStorage.setItem('slug', slug );
    navigate('/sign');
  }

  const navigateToPositiveReview = (navlink: string) => {
    slug && getCompanySpot(slug).then(data => sendSpotId(String(data.data.spot_id), i18n.language).then(() => {
      document.location.href = navlink
    }))
  }

  return <div className={styles.landingPageInteractButtonsContainer}>
    <p
      className={styles.landingPageButtonsTitle}
    >
      {t('Click the button and make an impact')}
    </p>
    <div className={styles.landingPageButtonsContainer}>
      <div className={styles.poorIconContainer} onClick={() => setFormShown(true)}>
        <PoorIconSvg className={styles.poorIcon} width={isMobile ? 60 : 86}
                     height={isMobile ? 61 : 87}/>
        <span>{t("Poor")}</span>
      </div>
      {/*{authData && navLink ?*/}
      {/*  (*/}
      {/*    <div className={styles.goodIconContainer}*/}
      {/*         onClick={() => navigateToPositiveReview(navLink)}>*/}
      {/*      <GoodIconSvg className={styles.goodIcon} width={isMobile ? 60 : 86}*/}
      {/*                   height={isMobile ? 61 : 87}/>*/}
      {/*      <span>{t("Good")}</span>*/}
      {/*    </div>*/}
      {/*  ) : (*/}
      {/*        <div className={styles.goodIconContainer} onClick={() => handeClickGood()}>*/}
      {/*          <GoodIconSvg className={styles.goodIcon} width={isMobile ? 60 : 86}*/}
      {/*                       height={isMobile ? 61 : 87}/>*/}
      {/*          <span>{t("Good")}</span>*/}
      {/*        </div>*/}
      {/*    )*/}
      {/*}*/}

      {
          navLink && <div className={styles.goodIconContainer} onClick={() => handeClickGood()}>
            <GoodIconSvg className={styles.goodIcon} width={isMobile ? 60 : 86}
                         height={isMobile ? 61 : 87}/>
            <span>{t("Good")}</span>
          </div>
      }
    </div>
    {
        showPrivacyPolicy && <p className={styles.landingPageTerms}>
          <Link className={'grey-link'} to={app_urls.client.privacy}>{t("Privacy Policy")}</Link>
          <Link className={'grey-link'} to={app_urls.client.imprint}>{t("Imprint")}</Link>
        </p>
    }
  </div>
}

export default LandingReviewButtons;