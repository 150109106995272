import React from 'react'
import Logo from 'components/Logo'

import styles from './Imprint.module.scss'
import Button from 'components/uiKit/Button/Button'
import { useTranslation } from 'react-i18next'

const Imprint: React.FC = (): React.ReactElement => {
    const { t } = useTranslation();
    
    return (
        <div className={styles.imprint}>
            <Button variant="back">{t("Back")}</Button>

            <Logo className={styles.logo}/>
            <div className={styles.block}>
                <p className={styles.title}>
                    Reviro Limited
                </p>

                <p className={styles.info}>
                    <span>
                        {t('imprint.registrationNumber')}
                    </span>
                    <span>
                        HE454869
                    </span>
                </p>
                <p className={styles.info}>
                    <span>
                        {t('imprint.vatNumber')}
                    </span>
                    <span>
                        60041352M
                    </span>
                </p>
                <p className={styles.info}>
                    <span>
                        {t('imprint.address')}
                    </span>
                    <span>
                        Pavlou Valdaseridi, 2A, 6018, Larnaka, Cyprus
                    </span>
                </p>
            </div>

            <div className={styles.block}>
                <p className={styles.title}>
                    {t('imprint.contact')}
                </p>
                <p className={styles.info}>
                    <span>
                        Email:
                    </span>
                    <span>
                        <a href="mailto:support@reviro.co" rel="noopener noreferrer" target="_blank">
                            support@reviro.co
                        </a>
                    </span>
                </p>
                <p className={styles.info}>
                    <span>
                        Tel:
                    </span>
                    <span>
                        <a href="tel:+498937049911" rel="noopener noreferrer" target="_blank">
                            +49 8937049911
                        </a>
                    </span>
                </p>
            </div>
        </div>
    )
}

export default Imprint
