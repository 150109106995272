import React, {useContext} from 'react'
import {useNavigate} from 'react-router-dom'
import {ClientContext} from "../service/ClientContext";

const Logo: React.FC<{className?: string}> = ({className = ''}): React.ReactElement => {
    const logo = require('../assets/logo.png')
    const {slug = ''} =  useContext<any>(ClientContext);
    const navigate = useNavigate()

    return (
        <span className={className} onClick={() => navigate('/' + slug)} style={{cursor: 'pointer'}}>
            <img id="logo"  src={logo} alt="" />
        </span>
    )
}

export default Logo
