import React from "react";
import { AppProvider } from "./service/AppContext";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import AppRoutes from "./Routes";
import "./App.css";
import {IntercomProvider} from "react-use-intercom";

import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

const pk: string | undefined = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

const stripePromise: any = loadStripe(pk || "");
const INTERCOM_APP_ID = 'fmt9whuj';

const queryClient = new QueryClient()

function App() {

  return (
    <div className="App">
      <AppProvider>
        <QueryClientProvider client={queryClient}>
          <Elements stripe={stripePromise}>
            <IntercomProvider appId={INTERCOM_APP_ID}>
              <AppRoutes />
              <ToastContainer/>
            </IntercomProvider>
          </Elements>
        </QueryClientProvider>
      </AppProvider>
    </div>
  );
}

export default App;
