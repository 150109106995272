import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { url } from "service/config";
import Button from "components/uiKit/Button/Button";
import { AppContext } from "service/AppContext";
import { loginAsync } from "features/business/companySlice";
import { useAppDispatch } from "app/hooks";

import styles from './BusinessSignIn.module.scss'
import SelectLanguage from "../../../../components/businessAuth/SelectLanguage";

function BusinessSignIn() {
  const { t } = useTranslation();
  const { setUser } = useContext<any>(AppContext);
  const dispatch = useAppDispatch();
  const logo = require("assets/logo.png");
  const googleIcon = require("assets/google-icon.png");
  const navigate = useNavigate();

  const [loginUrl, setLoginUrl] = useState<string | null>(null);
  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [backendError, setBackendError] = useState("");
  const handleEmailChange = (event: any) => {
    setBackendError("");
    setUserEmail(event.target.value);
  };

  const handlePasswordlChange = (event: any) => {
    setBackendError("");
    setPassword(event.target.value);
  };

  const [roleIds, setRoleIds] = useState<any>(null);

  useEffect(() => {
    fetch(`${url}/api/role`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong!");
      })
      .then((data) => {
        setRoleIds(data.data);
      });
  }, []);

  // const roleId = roleIds
  //   ? roleIds.find((item: { name: string }): any => item.name === "business").id
  //   : null;

  // localStorage.setItem("role", roleId);

  useEffect(() => {
    fetch(`${url}/api/auth/redirect`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong!");
      })
      .then((data) => {
        setLoginUrl(data.data.url);
        localStorage.removeItem('review')
        localStorage.setItem('role', '2')
      })
      .catch((error) => console.error(error));
  }, []);

  const onSubmit = (e: any) => {
    e.preventDefault();

    dispatch(loginAsync(userEmail, password))
      .then((data: any) => {
        if (data.company_id) {
          setUser(data)
          navigate("/business/dashboard");
        } else {
          navigate("/business/onboarding");
        }
      })
      .catch((error: any) => {
        console.log('epta');
        setBackendError("Wrong email or password!");
      });
  };

    const isProductionHost = 'app.reviro.co' === document.location.host;

    return (
    <div className={styles.signRedirect}>
      <h1>{t("Welcome to Reviro")}</h1>
      <img src={logo} alt="" style={{ maxWidth: "100px" }} />

      {loginUrl && (
        <a className={styles.googleSign} href={loginUrl}>
          <img src={googleIcon} alt="" />
          <span>{t("Continue with Google")}</span>
        </a>
      )}
      <p>{t("Or")}</p>
      <input        
        type="email"
        placeholder={t('Email address')}
        name="email"
        value={userEmail}
        onChange={handleEmailChange}
      />
      <input        
        type="password"
        placeholder={t('Password')}
        name="password"
        value={password}
        onChange={handlePasswordlChange}
      />
      {backendError && <p style={{ color: "red", textAlign: "center" }}>{backendError}</p>}
      <Link to={"/business/forgot-password"}>{t("Forgot password?")}</Link>
      <div className={styles.bottomContainer}>
        <Button onClick={onSubmit}>
          {t("Sign in")}
        </Button>

        <div className="" style={{ padding: "10px", textAlign: "center" }}>
          <Link to='/terms' className="grey-link">{t("By continuing you agree to our Terms and Privacy Policy.")}</Link>
        </div>
          { !isProductionHost &&
              <p>
                  {t("Don't have an account?")}
                  <Link to='/business/signup'>{t("Sign up")}</Link>
              </p>
          }
          <SelectLanguage/>
      </div>
    </div>
  );
}

export default BusinessSignIn;
