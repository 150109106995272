import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ManageProfileModal from "../ManageProfileModal";
import { getCookie } from "pages/client/PoorReview";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { CounterSliceState, selectCompany, logoutAsync } from "features/business/companySlice";

import './ModalProfile.scss';
import { useTranslation } from "react-i18next";

const ModalProfile = ({ shown, setShow }: any) => {  
  const { t } = useTranslation();
  const company = useAppSelector(selectCompany) as CounterSliceState;
  const userData = getCookie();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  
  const email = userData?.data?.email || company?.company?.email;

  const icon = require("assets/avatar.png");
  const exit = require("assets/exit.png");

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div
      className="pop-modal"
      style={{
        display: shown ? "block" : "none"
      }}
    >
      <div className="user">
        <img src={icon} alt="avatar"/>
        <div className="user-data">
          <p className="grey-link p-0 m-0 fw-bold overflow-ellipsis">{email}</p>
          <p className="green-text fw-bold p-0 mt-1"
            onClick={() => {
              setShow(false);
              handleOpen();
            }}
          >
            {t("Account Info")}
          </p>
        </div>
      </div>

      <div className="logout" onClick={() => {
          dispatch(logoutAsync());            
          navigate("/business/signin");          
        }}
      >
        <img src={exit} alt="logout"/>
        <span>{t("Log out")}</span>
      </div>
      
      <ManageProfileModal show={open} close={handleClose} />
    </div>
  );
};

export default ModalProfile;
