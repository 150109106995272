
import React, { ReactNode, ButtonHTMLAttributes } from 'react';
import './Button.scss';
import { useNavigate } from 'react-router-dom';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    children: ReactNode;
    variant?: 'primary' | 'secondary' | 'link' | 'back';
}

const Button: React.FC<ButtonProps> = ({ children, variant = 'primary', ...props }) => {
    const arrow = require("../../../assets/left-arrow.png");
    const className = `button ${variant}`;
    const navigate = useNavigate();

    return (
        variant === 'back' ? <div
            className="btn-back col"
            onClick={() => navigate(-1)}>
            <img src={arrow} alt="back" width={30} height={30} />
            <span>{children}</span>
        </div> :
        <button className={className} {...props}>
            {children}
        </button>
    );
};

export default Button;
