import React, { createContext, useState, useEffect, ReactNode, Dispatch } from "react";
import getCookie from "../utils/getCookie";
import i18n from "../i18n";
import { getUser, userRegister, userLogin, userLogout } from "../api/userApi";
import { setCookie } from "../utils/setCookie";
import { useNavigate } from "react-router-dom";

interface AuthToken {
  token: string;
}

interface User {
  id: string;
  exp: number;
}

interface AppContextData {
  shown: boolean;
  setShown: React.Dispatch<React.SetStateAction<boolean>>;
  authData: string | null;
  accessToken: string | null;
  formShown: boolean;
  setFormShown: React.Dispatch<React.SetStateAction<boolean>>;
  location: string | null;
  setLocation: React.Dispatch<React.SetStateAction<string | null>>;
  allReviews: object[] | null;
  setAllReviews: React.Dispatch<React.SetStateAction<object[] | null>>;
  navlink: string | null;
  setNavlink: React.Dispatch<React.SetStateAction<string | null>>;
  language: string | null;
  setLanguage: React.Dispatch<React.SetStateAction<string | null>>;
  userInfo: any;
  loadUser: () => void;
  setUser: Dispatch<any>;
  login: (email: string, password: string) => void;
  logout: () => void;
  registerUser: (email: string, lang: string, name: string, role_id: number) => void;
}

export const AppContext = createContext<AppContextData | undefined>(undefined);

interface AppProviderProps {
  children: ReactNode;
}

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const authData = getCookie("authData");
  let accessToken = getCookie("accessToken");
  if (accessToken) {
    accessToken = accessToken.replace(/"/g, "");
  }

  const navigate = useNavigate();

  const [location, setLocation] = useState<string | null>(null);
  const [allReviews, setAllReviews] = useState<object[] | null>(null);

  const [navlink, setNavlink] = useState<string | null>(null);

  const [shown, setShown] = useState(false);

  const [formShown, setFormShown] = useState(false);

  const [language, setLanguage] = useState<string | null>("de");
  useEffect(() => {
    const lang = localStorage.getItem("language");
    if (lang) {
      setLanguage(lang);
    } else {
      localStorage.setItem("language", "de");
      setLanguage("de");
    }
  }, []);

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    } else {
      i18n.changeLanguage("de");
    }
  }, [language]);

  const [user, setUser] = useState<any>(null);

  const loadUser = async () => {
    return getUser().then((data) => {
      console.log("data", data);
      setUser({ ...data });
    });
  };

  const login = async (email: string, password: string) => {
    return userLogin(email, password).then((data) => {
        // console.log("userLogin", data.data.access_token);
        setCookie("accessToken", data.data.access_token, 1);
        setUser({ ...data });
        return data.data
    });
  };

  const logout = async () => {
    return userLogout().then((data) => {
      console.log('logout', data);
      setCookie("accessToken", '', 1);
      setUser(null);
      navigate("/business/signin");
      return;
    });
  };

  const registerUser = async (email: string, lang: string, name: string, role_id: number) => {
    userRegister(email, lang, name, role_id).then((data) => {
      console.log("userRegister", data);
      setCookie("accessToken", data.data.access_token, 1);
    });
  }

  const contextData: AppContextData = {
    shown,
    setShown,
    authData,
    accessToken,
    formShown,
    setFormShown,
    location,
    setLocation,
    allReviews,
    setAllReviews,
    navlink,
    setNavlink,
    language,
    setLanguage,
    userInfo: user,
    loadUser,
    setUser,
    login,
    logout,
    registerUser,
  };

  return (
    <AppContext.Provider value={contextData}>{children}</AppContext.Provider>
  );
};
