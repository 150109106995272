import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {Link, useSearchParams} from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import AddAddressForm from "./components/AddressFormNew";
import CreateForestForm from "./components/CreateForestForm";
import SelectBillingPlan from "./components/SelectBillingPlan/SelectBillingPlan";
import GoalsAndSource from "./components/GoalsAndSource";
import { AppContext } from "service/AppContext";
import { ServiceFunctions } from "service/serviceFunctions";
import ProgresBar from "./components/ProgressBar";
import MobileProgresBar from "./components/MobileProgressBar";
import "./BusinessOnboarding.scss";

const lngs: any = {
  en: { nativeName: "English" },
  de: { nativeName: "Deutsch" },
};

export const activeIndex = [
  [1,2],
  [3],
  [4],
  [5]
]

const BusinessRegistration = () => {
  interface RegData {
    company_name: string;
    spot_name: string;
    spot_slug: string;
    spot_id: string;
    spot_address: string;
    spot_link: string | null;
    question_ids: number[] | null;
  }

  interface Question {
    id: number;
    type_name?: string;
    text: string;
  }

  interface Plan {
    id: number;
    name: string;
    price: string;
  }

  const {t} = useTranslation();

  const route = [
    t('Create Account'),
    t('Connect business/product'),
    t('Set up your profile'),
    t('Plan & billing'),
  ];
  const _STEP_FILED_PARAMS = 'step'

  const { authData, language, setLanguage, userInfo } = useContext<any>(AppContext);
  const [params, setParams] = useSearchParams();

  const initStep = params.get(_STEP_FILED_PARAMS) || '1'
  const [stage, setStage] = useState<string>("Create Account");
  const [step, stepChange] = useState<number>(+initStep);

  const setStep = (value: number) => {
    setParams(prev => {
      prev.set(_STEP_FILED_PARAMS, value.toString())
      return prev
    })
    stepChange(value)
  }

  const [questions, setQuestions] = useState<Question[] | null>(null);
  useEffect(() => {
    ServiceFunctions.getQuestions(language).then((data) => {
      data ? setQuestions(data.data) : setQuestions(null);
    });
  }, [language]);

  const goalsQuestions = questions
    ? questions.filter((item) => item.type_name === "goal")
    : [];
  const sourceQuestions = questions
    ? questions.filter((item) => item.type_name === "channel")
    : [];

  const [progress, setProgress] = useState<RegData | null>({
    company_name: "",
    spot_name: "",
    spot_slug: "",
    spot_id: "",
    spot_address: "",
    spot_link: "",
    question_ids: [],
  });

  const regData = localStorage.getItem("regData");

  useEffect(() => {
    const currentStage = regData
      ? JSON.parse(regData)
      : {
          company_name: "",
          spot_name: "",
          spot_slug: "",
          spot_id: "",
          spot_address: "",
          spot_link: "",
          question_ids: [],
        };
    setProgress(currentStage);
  }, [regData?.length]);

  const [plans, setPlans] = useState<Plan[] | null>(null);
  useEffect(() => {
    ServiceFunctions.getTariffs(language).then((data) => {
      data ? setPlans(data.data) : setPlans(null);
    });
  }, [language]);

  useEffect(() => {
    if (+initStep !== step) {
      setStage(initStep)
    }

  }, [initStep]);

  useEffect(() => {
    if (userInfo?.spot_slug) {
      setStep(5)
    }
  }, [userInfo]);

  const getComponent = (setStage: any, setStep: any) => {
    if (
      step < 3
    ) {
      return (
        <GoalsAndSource
          goals={goalsQuestions || []}
          sources={sourceQuestions || []}
          progress={progress}
          setProgress={setProgress}
          setStage={setStage}
          setStep={setStep}
          step={step}
        />
      );
    }

    if (step === 3) {
      return (
        <AddAddressForm
          progress={progress}
          setProgress={setProgress}
          setStage={setStage}
          setStep={setStep}
        />
      );
    }

    if (step === 4) {
      return (
        <CreateForestForm
          progress={progress}
          setProgress={setProgress}
          setStage={setStage}
          setStep={setStep}
        />
      );
    }

    return (
      <SelectBillingPlan
        plans={plans}
        progress={progress}
        setProgress={setProgress}
        setStage={setStage}
      />
    );
  };

  return (
    <div>
      <ProgresBar
        stage={stage}
        route={route}
        setStage={setStage}
        step={step}
        setStep={setStep}
      />
      <MobileProgresBar
        stage={stage}
        route={route}
        setStage={setStage}
        step={step}
        setStep={setStep}
      />
      <div className="reg-wrapper">
        {getComponent(setStage, setStep)}
      </div>
      <div
        className="text-center pb-2"
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          backgroundColor: "white",
        }}
      >
        <Dropdown>
          <Dropdown.Toggle
            variant=""
            id="dropdown-basic"
            style={{ zIndex: 9999999, padding: 0 }}
          >
            {lngs[language]?.nativeName}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {Object.keys(lngs).map((lng) => (
              <Dropdown.Item
                key={lng}
                onClick={() => {
                  localStorage.setItem("language", lng);
                  localStorage.removeItem("review");
                  setLanguage(lng);
                }}
              >
                {lngs[lng].nativeName}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default BusinessRegistration;
