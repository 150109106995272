import { setCookie } from "utils/setCookie";
import { apiInstance } from "./api";

export const changePassword = async (oldPassword: string, newPassword: string) => {
  const response = await apiInstance().post(`/api/auth/change-password`, {
    oldPassword,
    newPassword,
  });
  return response.data;
};

export const getUser = async () => {
  const response = await apiInstance().get(`/api/user`);
  return response.data.data;
};

export const userLogin = async (email: string, password: string) => {
  const response = await apiInstance().post(`/api/auth/login`, {
    email,
    password,
  });
  setCookie("accessToken", response.data.data.access_token, 1);
  return response.data.data;
};

export const userRegister = async (email: string, lang: string, name: string, role_id: number) => {
  try {
    const response = await apiInstance().post(`/api/auth/register`, {
      name,
      email,
      lang,
      role_id,
    });
    setCookie("accessToken", response.data.data.access_token, 1);
    return response.data;
  } catch (error: any) {
    if (error && error.response) {
      return Promise.reject(error.response.data);
    }
  }
};

export const userLogout = async () => {
  const response = await apiInstance().post(`/api/auth/logout`);
  return response.data;
};
