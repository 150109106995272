import React from 'react';
import {useUserCompanies} from "../../../../../queries/companies/useUserCompanies";
import {Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {app_urls} from "../../../../../routes/urls";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";

export default function () {
    const [page, setPage] = React.useState(1)
    const navigate = useNavigate()
    const {data: companies} = useUserCompanies(page);

    return (
        <div>
            <div style={{
                minHeight: "600px",
            }}>
                <TableContainer>
                    <Table style={{
                        width: "100%",
                        verticalAlign: "top",
                        borderSpacing: '10px'
                    }}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>Company Name</TableCell>
                                <TableCell>Website</TableCell>
                                <TableCell>Spots Count</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {companies?.data.map(company => {
                                return (
                                    <TableRow
                                        key={company.id}
                                        style={{
                                            cursor: "pointer"
                                        }}
                                        onClick={() => {
                                            localStorage.setItem('company_id', String(company.id));
                                            return navigate('/business' + app_urls.business.spots(String(company.id)))
                                        }}
                                    >
                                        <TableCell>{company.name}</TableCell>
                                        <TableCell>{company.website}</TableCell>
                                        <TableCell>{company.spots_count}</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            {/*pagination mui*/}
            <Box display={'flex'} justifyContent={'center'} pt={3}>
                <Pagination count={companies?.meta.last_page || 1} color="primary" onChange={
                    (event, value) => {
                        setPage(value)
                    }
                } />
            </Box>
        </div>
    );
}
