import React from 'react'
import Logo from 'components/Logo'

import styles from './TermsAndConditions.module.scss'
import Button from 'components/uiKit/Button/Button'
import { useTranslation } from 'react-i18next'

const TermsAndConditions: React.FC = (): React.ReactElement => {
    const { t } = useTranslation();
    
    return (
        <div className={styles.terms}>
            <Button variant="back" >{t("Back")}</Button>
            
            <Logo className={styles.logo} />

            <p className={styles.title}>
                Terms and Conditions
            </p>

            <main>
                <section>
                    <p className={styles.sectionTitle}>
                        {t('terms.introductionTitle')}
                    </p>
                    <p>
                        {t('terms.introduction')}
                    </p>
                    <p>
                        {t('terms.usingService')}
                    </p>
                    <p>
                        {t('terms.beforeUsingService')}
                    </p>
                </section>
                <section>
                    <p className={styles.sectionTitle}>
                        {t('terms.definitionsTitle')}
                    </p>
                    <p>
                        {t('terms.forThisAgreement')}
                    </p>
                    <p>
                        <ul>
                            <li>
                                {t('terms.clientDefinition')}
                            </li>
                            <li>
                                {t('terms.userDefinition')}
                            </li>
                            <li>
                                {t('terms.heroboardDefinition')}
                            </li>
                            <li>
                                {t('terms.reviewContentDefinition')}
                            </li>
                        </ul>
                    </p>
                </section>
                <section>
                    <p className={styles.sectionTitle}>
                        {t('terms.scopeOfServicesTitle')}
                    </p>
                    <p>
                        {t('terms.scopeOfServices.a')}
                    </p>
                    <p>
                        {t('terms.scopeOfServices.b')}
                    </p>
                    <p>
                        {t('terms.scopeOfServices.c')}
                    </p>
                    <p>
                        {t('terms.scopeOfServices.d')}
                    </p>
                    <p>
                        {t('terms.scopeOfServices.e')}
                    </p>
                    <p>
                        {t('terms.scopeOfServices.f')}
                    </p>
                </section>
                <section>
                    <p className={styles.sectionTitle}>
                        {t('terms.clientObligationsTitle')}
                    </p>
                    <p>
                        {t('terms.clientObligations.a')}
                    </p>
                    <p>
                        {t('terms.clientObligations.b')}
                    </p>
                    <p>
                        {t('terms.clientObligations.c')}
                    </p>
                    <p>
                        {t('terms.clientObligations.d')}
                    </p>
                    <p>
                        {t('terms.clientObligations.e')}
                    </p>
                </section>
                <section>
                    <p className={styles.sectionTitle}>
                        {t('terms.feesAndPaymentTitle')}
                    </p>
                    <p>
                        {t('terms.feesAndPayment.a')}
                    </p>
                    <p>
                        {t('terms.feesAndPayment.b')}
                    </p>
                    <p>
                        {t('terms.feesAndPayment.c')}
                    </p>
                    <p>
                        {t('terms.feesAndPayment.d')}
                    </p>
                    <p>
                        {t('terms.feesAndPayment.e')}
                    </p>
                </section>
                <section>
                    <p className={styles.sectionTitle}>
                        {t('terms.durationOfContractTitle')}
                    </p>
                    <p>
                        {t('terms.durationOfContract.a')}
                    </p>
                    <p>
                        {t('terms.durationOfContract.b')}
                    </p>
                    <p>
                        {t('terms.durationOfContract.c')}
                    </p>
                    <p>
                        {t('terms.durationOfContract.d')}
                    </p>
                    <p>
                        {t('terms.durationOfContract.e')}
                    </p>
                    <p>
                        {t('terms.durationOfContract.f')}
                    </p>
                    <p>
                        {t('terms.durationOfContract.g')}
                    </p>
                </section>
                <section>
                    <p className={styles.sectionTitle}>
                        {t('terms.intellectualPropertyTitle')}
                    </p>
                    <p>
                        {t('terms.intellectualProperty.a')}
                    </p>
                    <p>
                        {t('terms.intellectualProperty.b')}
                    </p>
                    <p>
                        {t('terms.intellectualProperty.c')}
                    </p>
                    <p>
                        {t('terms.intellectualProperty.d')}
                    </p>
                    <p>
                        {t('terms.intellectualProperty.e')}
                    </p>
                </section>
                <section>
                    <p className={styles.sectionTitle}>
                        {t('terms.confidentialityTitle')}
                    </p>
                    <p>
                        {t('terms.confidentiality.a')}
                    </p>
                    <p>
                        {t('terms.confidentiality.b')}
                    </p>
                    <p>
                        {t('terms.confidentiality.c')}
                    </p>
                </section>
                <section>
                    <p className={styles.sectionTitle}>
                        {t('terms.grantingRightsTitle')}
                    </p>
                    <p>
                        {t('terms.digitalGraphicsTitle')}
                    </p>
                    <p>
                        {t('terms.grantingRights.a')}
                    </p>
                    <p>
                        {t('terms.grantingRights.b')}
                    </p>
                    <p>
                        {t('terms.printTemplatesTitle')}
                    </p>
                    <p>
                        {t('terms.grantingRights.c')}
                    </p>
                    <p>
                        {t('terms.grantingRights.d')}
                    </p>
                    <p>
                        {t('terms.grantingRights.e')}
                    </p>
                    <p>
                        {t('terms.grantingRights.f')}
                    </p>
                    <p>
                        {t('terms.grantingRights.g')}
                    </p>
                </section>
                <section>
                    <p className={styles.sectionTitle}>
                        {t('terms.limitationOfLiabilityTitle')}
                    </p>
                    <p>
                        {t('terms.limitationOfLiability.a')}
                    </p>
                    <p>
                        {t('terms.limitationOfLiability.b')}
                    </p>
                </section>
                <section>
                    <p className={styles.sectionTitle}>
                        {t('terms.terminationTitle')}
                    </p>
                    <p>
                        {t('terms.termination.a')}
                    </p>
                    <p>
                        {t('terms.termination.b')}
                    </p>
                </section>
                <section>
                    <p className={styles.sectionTitle}>
                        {t('terms.governingLawTitle')}
                    </p>
                    <p>
                        {t('terms.governingLaw.a')}
                    </p>
                    <p>
                        {t('terms.governingLaw.b')}
                    </p>
                    <p>
                        {t('terms.governingLaw.c')}
                    </p>
                </section>
                <section>
                    <p className={styles.sectionTitle}>
                        {t('terms.disclaimerTitle')}
                    </p>
                    <p>
                        {t('terms.disclaimer.a')}
                    </p>
                    <p>
                        {t('terms.disclaimer.b')}
                    </p>
                    <p>
                        {t('terms.disclaimer.c')}
                    </p>
                    <p>
                        {t('terms.disclaimer.d')}
                    </p>
                    <p>
                        {t('terms.disclaimer.e')}
                    </p>
                </section>
                <section>
                    <p className={styles.sectionTitle}>
                        {t('terms.amendmentsTitle')}
                    </p>
                    <p>
                        {t('terms.amendments.a')}
                    </p>
                    <p>
                        {t('terms.amendments.b')}
                    </p>
                    <p>
                        {t('terms.amendments.c')}
                    </p>
                    <p>
                        {t('terms.amendments.d')}
                    </p>
                </section>
            </main>

            <p className={styles.contact}>
                {t('terms.contactUs')} <a
                href="mailto:support@reviro.co" rel="noopener noreferrer" target="_blank">support@reviro.co</a>.
            </p>

            <div className={styles.companyInfo}>
                <p>
                    Reviro Limited
                </p>
                <p>
                Pavlou Valdaserid 2A, 6018 Larnaca, Cyprus
                </p>
                <p>
                    Phone Number: <a href="tel:+498937049911" rel="noopener noreferrer" target="_blank">+49 8937049911</a>
                </p>
            </div>

            <p className={styles.date}>
                13 February 2024
            </p>
        </div>
    )
}

export default TermsAndConditions
