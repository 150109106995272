import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

export default function ResponsiveDialog({ open, title, text, handleClose, handleClick }: any) {
  const theme = useTheme();
  const { t } = useTranslation();


  return (
    <Dialog
      fullScreen={false}
      open={open}
      aria-labelledby="responsive-dialog-title"
      style={{ zIndex: 9999999 }}
    >
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="secondary">
          {t("Logout")}
        </Button>
        <Button onClick={handleClick} autoFocus>
        {t("Enter Payment Information")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
