import React from 'react'
import { useTranslation } from 'react-i18next';
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from 'react-router-dom';

const LandingStatBlock = ({ total }: any) => {
    const { t } = useTranslation();
    const star = require('../assets/hero-star.png')

    const navigate = useNavigate()

    return (
        <div className='landing-stat-block' onClick={() => navigate('heroboard')}>
            <div className="d-flex align-items-center" >
                <img src={star} alt="" className='me-2' style={{ maxWidth: '40px' }} />
                <div>
                    <p className='bold-font m-0 heroboard-title'>{ total } {total !== 1 ? t("Heroes") : t("Hero")}</p>
                    <p className='m-0 heroboard-text'>{t(total !== 1  ? 'landing_stat_block.multiple' : 'landing_stat_block.single')}</p>
                </div>
            </div>
            <IoIosArrowForward className='arrow-right-icon' />
        </div>
    )
}

export default LandingStatBlock