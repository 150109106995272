import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/uiKit/Button/Button";
import "./GoalsAndSource.scss";
import GoalItem from "./GoalItem";

const BusinessGoalsForm = ({
  goals,
  progress,
  setProgress,
  setStage,
  setStep,
}: any) => {
  interface Goal {
    id: number;
    text: string;
    type_name: string;
  }

  const [objectives, setObjectives] = useState<number | null>(null);
  const { t } = useTranslation();
  console.log("BusinessGoalsForm");
  return (
    <div className="goals-wrapper">
      <h3 className="text-center fw-bold goals-title">
        {t("What are your business goals?")}
      </h3>
      <div className="goals-list">
        {goals &&
          goals.map((item: Goal) => (
              <GoalItem key={'goal_item' + item.id} onClick={setObjectives} label={item.text} id={item.id} />
          ))}
      </div>

        <div className="bottom-block">
            <div
                style={{padding: "0 20px", width: "600px"}}
                className="text-center continue-btn"
        >
            <button
                onClick={() => {
                    setProgress({
                ...progress,
                question_ids: [...progress.question_ids, objectives],
              });
              setStep(2);
            }}
            className={
              objectives ? "submit-review active-btn-green" : "submit-review"
            }
            style={{ width: "368px" }}
          >
            {t("Continue")}
          </button>
        </div>

        <Button
          onClick={() => {
            setProgress({
              ...progress,
              question_ids: [...progress.question_ids, objectives],
            });
            setStep(2);
          }}
          variant="link"
        >
          {t("Skip")}
        </Button>
      </div>
    </div>
  );
};

const InfoSourceForm = ({
  sources,
  progress,
  setProgress,
  setStage,
  setStep,
}: any) => {
  interface Source {
    id: number;
    text: string;
    type_name: string;
  }

  const [infoSrc, setInfoSrc] = useState<number | null>(null);
  const { t } = useTranslation();
  return (
    <div className="goals-wrapper">
      <h3 className="text-center fw-bold goals-title">
        {t("Please let us know how you heard about Reviro")}
      </h3>
      <div className="goals-list">
        {sources &&
          sources.map((item: Source) => (
              <GoalItem key={'goal_item' + item.id} onClick={setInfoSrc} label={item.text} id={item.id} />
          ))}
      </div>
      <div className="bottom-block">
        <div
          style={{ padding: "0 20px", width: "600px" }}
          className="text-center continue-btn"
        >
          <button
            onClick={() => {
              setProgress({
                ...progress,
                question_ids: [...progress.question_ids, infoSrc],
              });
              localStorage.setItem(
                "regData",
                JSON.stringify({
                  ...progress,
                  question_ids: [...progress.question_ids, infoSrc],
                })
              );
              setStage("Connect business/product");
              setStep(3);
            }}
            className={
              infoSrc ? "submit-review active-btn-green" : "submit-review"
            }
            style={{ width: "368px" }}
          >
            {t("Continue")}
          </button>
        </div>

        <div className="skip-btn">
          <Button
            onClick={() => {
              setProgress({
                ...progress,
                question_ids: [...progress.question_ids, infoSrc],
              });
              localStorage.setItem(
                "regData",
                JSON.stringify({
                  ...progress,
                  question_ids: [...progress.question_ids, infoSrc],
                })
              );
              setStage("Connect business/product");
              setStep(3);
            }}
            variant="link"
          >
            {t("Skip")}
          </Button>
        </div>
      </div>
    </div>
  );
};

const GoalsAndSouce = ({
  goals,
  sources,
  progress,
  setProgress,
  setStage,
  setStep,
  step,
}: any) => {
  if (
    step === 1 ||
    (progress.question_ids && progress.question_ids.length < 1)
  ) {
    return (
      <BusinessGoalsForm
        goals={goals}
        progress={progress}
        setProgress={setProgress}
        setStage={setStage}
        setStep={setStep}
      />
    );
  }

  if (
    step === 2 ||
    (progress.question_ids && progress.question_ids.length > 2)
  ) {
    return (
      <InfoSourceForm
        sources={sources}
        progress={progress}
        setProgress={setProgress}
        setStage={setStage}
        setStep={setStep}
      />
    );
  }

  return (
    <BusinessGoalsForm
      goals={goals}
      progress={progress}
      setProgress={setProgress}
      setStage={setStage}
      setStep={setStep}
    />
  );
};

export default GoalsAndSouce;
