import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, Link} from "react-router-dom";
import {apiInstance} from "../../../../api/api";

import styles from './BusinessCreatePassword.module.scss'
import SelectLanguage from "../../../../components/businessAuth/SelectLanguage";
import {app_urls} from "../../../../routes/urls";
import Button from "react-bootstrap/Button";
import {AxiosResponse} from "axios";


interface ICreatePasswordResponse {
    status: "success" | "error";
    checkout_url: string;
}

export function BusinessCreatePasswordPage() {
    const { t } = useTranslation();
    const logo = require("assets/logo.png");

    const navigate = useNavigate();

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get('token');
    const company_id = params.get('company_id');

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");

    const handlePasswordChange = (event: any) => {
        if(passwordError){
            setPasswordError("");
        }
        setPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event: any) => {
        if(passwordError){
            setPasswordError("");
        }
        setConfirmPassword(event.target.value);
    };

    const onSubmit = (e: any) => {

        if(password !== confirmPassword){
            setPasswordError(t("Passwords do not match"))
            return;
        }

        apiInstance().post("/api/save-password", {password, token, company_id}).then(
            (response: AxiosResponse<ICreatePasswordResponse>) => {
                if(response.data.status === "success"){
                    // return window.open(response.data.checkout_url, "_self");
                    return navigate(app_urls.business.signin);
                }
                navigate(app_urls.business.signin)
            }
        ).catch((error) => {
                setPasswordError(error.response.data.message)
            }
        )
    };

    return (
        <div className={styles.signRedirect}>
            <h1>{t("Create Password")}</h1>
            <img src={logo} alt="" style={{maxWidth: "100px"}}/>
            <p>{t("Setup your password")}</p>
            <input
                type="password"
                placeholder={t('Password')}
                name="password"
                value={password}
                onChange={handlePasswordChange}
            />
            <input
                type="password"
                placeholder={t('Confirm Password')}
                name="confirm-password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
            />
            {passwordError && <p style={{color: "red", textAlign: "center"}}>{passwordError}</p>}

            <div className={styles.bottomContainer}>
                <Button onClick={onSubmit}>
                    {t("Create Password")}
                </Button>
                <div className="" style={{padding: "10px", textAlign: "center"}}>
                    <Link to='/terms' className="grey-link">{t("By continuing you agree to our Terms and Privacy Policy.")}</Link>
                </div>
            </div>
            <SelectLanguage/>
        </div>
    );
 }


export default BusinessCreatePasswordPage;
