import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from '../service/AppContext';
import { getCookie } from '../pages/client/PoorReview';
import { RegData } from '../models'
import {getCompanySpot, sendSpotId} from "../api/companyApi";
import {useTranslation} from "react-i18next";
import {apiInstance} from "../api/api";

interface AuthData {
    data: {
        name: string,
        avatar: string,
        access_token: string,
        email: string
    }
}

interface UserData {

}

function GoogleCallback() {

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<AuthData | {}>({});
    const location = useLocation();

    const role = localStorage.getItem('role');

    // On page load, we take "search" parameters 
    // and proxy them to /api/auth/callback on our Laravel API
    // make only one time request to the server
    useEffect(() => {
        if (!!location.search) {
            apiInstance().post(`/api/auth/callback${location.search}`, {
                role_id: Number(role),
                lang: localStorage.getItem('language') || 'en'
            }).then((response) => {
                if (response.data) {
                    setLoading(false);
                    setData(response.data);
                    document.cookie = `authData=${JSON.stringify(response.data)}; path=/`;
                    document.cookie = `accessToken=${JSON.stringify(response.data?.data?.access_token)}; path=/`;
                }
            }).catch((error) => {
                console.log(error)
            });

            location.search = '';
        }
    }, []);

    if (loading) {
        return <DisplayLoading />
    } else {
        return (
            <div>
               <DisplayData data={data} />
            </div>
        );
    }
}

function DisplayLoading() {
    return <div>Loading....</div>;
}

interface DisplayDataProps {
    data: object;
}

function DisplayData({ data }: DisplayDataProps) {

    const navigate = useNavigate()

    const { navlink, setNavlink } = useContext<any>(AppContext)
    const {i18n} = useTranslation();


    const review = localStorage.getItem('review');
    const userData = getCookie()
    const roles: string[] = userData ? userData.data.roles : null
    const businessAccount = roles ? roles.find(item => item === 'business') : false
    const company = userData ? userData.data.company_id : null;
    const regData: RegData | any = JSON.parse(localStorage.getItem('regData') || '{}');
    const slug = localStorage.getItem('slug')

    const navigateToPositiveReview = () => {
        slug && getCompanySpot(slug).then(data => sendSpotId(data.data.spot_id, i18n.language).finally(() => {
            window.location.href = data.data.google_map_link
        }))
    }

    useEffect(() => {
        const link = localStorage.getItem('navlink')
        setNavlink(link)
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (businessAccount && company !== null) {
                return navigate('/business/dashboard');
            }

            if (businessAccount && company) {
                return navigate('/business/onboarding');
            }

            if (regData && !review) {
                return navigate('/business/onboarding');
            }

            review === 'negative' ? navigate('/') : navigateToPositiveReview();
        }, 1000);
    }, [navlink, businessAccount, regData, company]);

    return (
        <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <h3>Success!</h3>
        </div>
    );
}


export default GoogleCallback;