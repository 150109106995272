import {useQuery} from "@tanstack/react-query";
import {apiInstance} from "../../api/api";
import queryKeys from "../queryKeys";

type Company = {
    id: number;
    user_id: number;
    name: string;
    tariff_id: number;
    created_at: string;
    updated_at: string;
    vat_number: string;
    deleted_at: string;
    website: string;
    size: string;
    spots_count: number;
}

type CompanyResponse = {
    data: Company[];
    links: {
        first: string;
        last: string;
        prev: string | null;
        next: string | null;
    };
    meta: {
        current_page: number;
        from: number;
        last_page: number;
        links: {
            url: string | null;
            label: string;
            active: boolean;
        }[];
    }
}

export const useUserCompanies = (page: number) => {
    return useQuery({
        queryKey: [queryKeys.userCompanies],
        queryFn: async () => {
            const response = await apiInstance().get<CompanyResponse>(`api/companies`, {
                params: {
                    page
                }
            });

            return response.data;
        },
    })
}