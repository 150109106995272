import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from '@mui/material/IconButton';

import styles from './ReviewTable.module.scss'

type ReviewModalProps = {
    open: boolean,
    children: string | React.ReactElement,
    handleClose: () => void,
}

const ReviewModal = ({ open, children, handleClose }: ReviewModalProps): React.ReactElement => {   
  const closeIcon = require('../../../../../assets/close.png');

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className={styles.reviewModalClose}         
        >
          <img src={closeIcon} alt="" />
        </IconButton>    
      <DialogContent className={styles.reviewModalContent}>
        {children}
      </DialogContent>
    </Dialog>
  );
}

export default ReviewModal
