import React from 'react'
import Logo from 'components/Logo'

import styles from './TermsAndConditions.module.scss'
import Button from 'components/uiKit/Button/Button'
import { useTranslation } from 'react-i18next'

const TermsAndConditions: React.FC = (): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <div className={styles.terms}>
            <Button variant="back">{t("Back")}</Button>

            <Logo className={styles.logo}/>


            <main>
                <section>
                    <p className={styles.underlineSectionTitle}>
                        {t('privacyNotice.title')}
                    </p>
                    <p>
                        {t('privacyNotice.intro')}
                    </p>
                    <p>
                        {t('privacyNotice.gdpr')}
                    </p>
                </section>
                <section>
                    <p className={styles.underlineSectionTitle}>
                        {t('privacyNotice.titleWeCollect')}
                    </p>
                    <p>
                        {t('privacyNotice.infoWeCollect')}
                    </p>
                    <ul>
                        <li>{t('privacyNotice.infoWeCollectDetails.personalInformation')}</li>
                        <li>{t('privacyNotice.infoWeCollectDetails.reviewData')}</li>
                        <li>{t('privacyNotice.infoWeCollectDetails.usageInformation')}</li>
                        <li>{t('privacyNotice.infoWeCollectDetails.cookiesAndSimilar')}</li>
                    </ul>
                    <p>
                        {t('privacyNotice.websiteUsage')}
                    </p>
                    <ul>
                        <li>{t('privacyNotice.websiteUsageDetails.ipAddress')}</li>
                        <li>{t('privacyNotice.websiteUsageDetails.dateTimeRequest')}</li>
                        <li>{t('privacyNotice.websiteUsageDetails.timeZoneDifference')}</li>
                        <li>{t('privacyNotice.websiteUsageDetails.specificPageContent')}</li>
                        <li>{t('privacyNotice.websiteUsageDetails.accessStatus')}</li>
                        <li>{t('privacyNotice.websiteUsageDetails.dataTransferred')}</li>
                        <li>{t('privacyNotice.websiteUsageDetails.websiteReferring')}</li>
                        <li>{t('privacyNotice.websiteUsageDetails.browserType')}</li>
                        <li>{t('privacyNotice.websiteUsageDetails.operatingSystem')}</li>
                        <li>{t('privacyNotice.websiteUsageDetails.languageVersion')}</li>
                    </ul>
                    <p>
                        {t('privacyNotice.cookiesUsage')}
                    </p>
                </section>
                <section>
                    <p className={styles.underlineSectionTitle}>
                        {t('privacyNotice.howWeUseInfo')}
                    </p>
                    <p>
                        {t('privacyNotice.howWeUseInfoDetails')}
                    </p>
                    <ul>
                        <li>{t('privacyNotice.howWeUseInfoDetailsList.providingServices')}</li>
                        <li>{t('privacyNotice.howWeUseInfoDetailsList.communicating')}</li>
                        <li>{t('privacyNotice.howWeUseInfoDetailsList.analyzingUsage')}</li>
                        <li>{t('privacyNotice.howWeUseInfoDetailsList.complianceLegal')}</li>
                    </ul>
                    <p>
                        {t('privacyNotice.additionalDataCollection')}
                    </p>
                </section>
                <section>
                    <p className={styles.underlineSectionTitle}>
                        {t('privacyNotice.sharingInfo')}
                    </p>
                    <p>
                        {t('privacyNotice.sharingInfoDetails')}
                    </p>
                    <ul>
                        <li>{t('privacyNotice.sharingInfoDetailsList.serviceProviders')}</li>
                        <li>{t('privacyNotice.sharingInfoDetailsList.legalCompliance')}</li>
                        <li>{t('privacyNotice.sharingInfoDetailsList.businessTransfers')}</li>
                    </ul>
                </section>
                <section>
                    <p className={styles.underlineSectionTitle}>
                        {t('privacyNotice.legalBasis')}
                    </p>
                    <p>
                        {t('privacyNotice.legalBasisDetails')}
                    </p>
                    <ul>
                        <li>{t('privacyNotice.legalBasisDetailsList.provisionSecurity')}</li>
                        <li>{t('privacyNotice.legalBasisDetailsList.processingOrders')}</li>
                        <li>{t('privacyNotice.legalBasisDetailsList.consentMarketing')}</li>
                    </ul>
                </section>
                <section>
                    <p className={styles.underlineSectionTitle}>
                        {t('privacyNotice.dataSecurity')}
                    </p>
                    <p>
                        {t('privacyNotice.dataSecurityDetails')}
                    </p>
                </section>
                <section>
                    <p className={styles.underlineSectionTitle}>
                        {t('privacyNotice.yourLegalRights')}
                    </p>
                    <p>
                        {t('privacyNotice.yourLegalRightsDetails')}
                    </p>
                    <ul>
                        <li>
                            <strong>{t('privacyNotice.yourLegalRightsDetailsList.rightToBeInformedTitle')}</strong> - {t('privacyNotice.yourLegalRightsDetailsList.rightToBeInformed')}
                        </li>
                        <li>
                            <strong>{t('privacyNotice.yourLegalRightsDetailsList.rightOfAccessTitle')}</strong> - {t('privacyNotice.yourLegalRightsDetailsList.rightOfAccess')}
                        </li>
                        <li>
                            <strong>{t('privacyNotice.yourLegalRightsDetailsList.rightOfRectificationTitle')}</strong> - {t('privacyNotice.yourLegalRightsDetailsList.rightOfRectification')}
                        </li>
                        <li>
                            <strong>{t('privacyNotice.yourLegalRightsDetailsList.rightToErasureTitle')}</strong> - {t('privacyNotice.yourLegalRightsDetailsList.rightToErasure')}
                        </li>
                        <li>
                            <strong>{t('privacyNotice.yourLegalRightsDetailsList.rightToRestrictProcessingTitle')}</strong> - {t('privacyNotice.yourLegalRightsDetailsList.rightToRestrictProcessing')}
                        </li>
                        <li>
                            <strong>{t('privacyNotice.yourLegalRightsDetailsList.rightToObjectToProcessingTitle')}</strong> - {t('privacyNotice.yourLegalRightsDetailsList.rightToObjectToProcessing')}
                        </li>
                        <li>
                            <strong>{t('privacyNotice.yourLegalRightsDetailsList.rightToDataPortabilityTitle')}</strong> - {t('privacyNotice.yourLegalRightsDetailsList.rightToDataPortability')}
                        </li>
                        <li>
                            <strong>{t('privacyNotice.yourLegalRightsDetailsList.rightToWithdrawConsentTitle')}</strong> - {t('privacyNotice.yourLegalRightsDetailsList.rightToWithdrawConsent')}
                        </li>
                        <li>
                            <strong>{t('privacyNotice.yourLegalRightsDetailsList.rightToReportAComplaintTitle')}</strong> - {t('privacyNotice.yourLegalRightsDetailsList.rightToReportAComplaint')}
                        </li>
                    </ul>
                </section>
                <section>
                    <p className={styles.underlineSectionTitle}>
                        {t('privacyNotice.retentionOfYourPersonalData')}
                    </p>
                    <p>
                        {t('privacyNotice.retentionOfYourPersonalDataDetails')}
                    </p>
                </section>
                <section>
                    <p className={styles.underlineSectionTitle}>
                        {t('privacyNotice.changesToThisPrivacyNotice')}
                    </p>
                    <p>
                        {t('privacyNotice.changesToThisPrivacyNoticeDetails')}
                    </p>
                </section>
                <section>
                    <p className={styles.underlineSectionTitle}>
                        {t('privacyNotice.contactUs')}
                    </p>
                    <p className={styles.contact}>
                        {t('privacyNotice.contactUsDetails')}
                        <span> </span>
                        <a href="mailto:support@reviro.co">support@reviro.co</a>
                    </p>
                </section>

                <p className={styles.date}>
                    {t('privacyNotice.effectiveDate')}
                </p>
            </main>
        </div>
    )
}

export default TermsAndConditions
