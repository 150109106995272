import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import {apiInstance} from "../../../../api/api";

const ModalEditPayment = ({ close, open, show, paymentMethod }: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<any>(null);

  const handleSubmitStripe = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    const cardElement: any = elements.getElement(CardElement);

    const { token, error } = await stripe.createToken(cardElement);

    if (error) {
      setError(error.message);
    } else {
      const result = await apiInstance().patch("/api/payment-method/payment", { payment_method_token: token.id });

      if (result.status === 200) {
        close();
      } else {
        setError("Error updating payment method");
      }
    }
  };

  return (
    <Modal show={show} onHide={close} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title
          style={{ paddingLeft: "18%", fontSize: "20px", fontWeight: "bold" }}
        >
          Edit payment method
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmitStripe}>
          <CardElement />
          <div className="d-flex justify-content-between pt-2 mt-3">
            <button
              className="col"
              type="button"
              style={{
                backgroundColor: "transparent",
                border: "none",
                outline: "none",
                padding: "8px",
                color: "grey",
                fontWeight: "500",
              }}
              onClick={() => {
                setError("");
                close();
              }}
            >
              Cancel
            </button>
            <button
              className="col"
              type="submit"
              style={{
                backgroundColor: "rgba(89, 200, 87, 1)",
                color: "white",
                fontWeight: "500",
                border: "none",
                outline: "none",
                padding: "8px",
                borderRadius: "12px",
              }}
              // onClick={handleSubmitStripe}
            >
              Save changes
            </button>
          </div>
          {error && <div>{error}</div>}
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalEditPayment;
