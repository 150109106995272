import React from "react";
import { useTranslation } from "react-i18next";
import ModalProfile from "./modalProfile/ModalProfile";
import useBusinessNav from "../../../../hooks/useBusinessNav";

const AccountTopBar = ({ name, rate, show, setShow }: any) => {
  const ratingArray = rate ? new Array(Math.floor(rate)).fill(0) : [];
  const { t } = useTranslation();
  const {current} = useBusinessNav()
  const staricon = require("assets/gold-star.png");
  // const noticon = require("assets/notification-icon.png");
  // const redround = require("assets/redround.png");
  const accountIcon = require("assets/account-info-icon.png");

  // rate = rate ? parseFloat(rate).toString().slice(0, 3) : 0;

  return (
    <div className="account-top-bar" style={{ position: "relative" }}>
      <div className="active-component-name">
        <h3 className="fw-bold">{current?.name}</h3>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <div className="account-rating">
          <div className="d-flex">
            {ratingArray &&
              ratingArray.map((item, index) => (
                <img
                  src={staricon}
                  key={index}
                  alt=""
                  style={{ maxWidth: "25px" }}
                />
              ))}
          </div>
          <p className="m-0 fw-bold">{t("Average rating:")} {`${rate || 0}/5`}</p>
        </div>
        {/* <div className="notification">
                    <img src={noticon} alt="" style={{ maxWidth: '24px' }} />
                    <img src={redround} className='indicator' alt="" style={{ maxWidth: '10px' }} />
                </div> */}
        <div className="profile-menu">
          <img
            src={accountIcon}
            alt=""
            style={{ maxWidth: "60px", cursor: "pointer" }}
            onClick={() => setShow(!show)}
          />
        </div>
      </div>
      <ModalProfile shown={show} setShow={setShow} />
    </div>
  );
};

export default AccountTopBar;
