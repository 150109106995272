import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "app/hooks";
import { logoutAsync } from "features/business/companySlice";
import { AppContext } from "service/AppContext";
import {activeIndex} from "../BusinessOnboarding";

const MobileProgresBar = ({ stage, route, setStage, step, setStep }: any) => {
  const { t } = useTranslation();
  const green = require("assets/green-round.png");
  const grey = require("assets/grey-round.png");
  const arrow = require("assets/left-arrow.png");
  const exit = require("assets/exit.png");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { logout } = useContext<any>(AppContext);

  return (
    <div className="create-progress-mobile">
      {step > 1 && (
        <div
          className="hero-return"
          onClick={() => {
            const stageIndex = route.findIndex((x: string) => x === stage);
            setStep(step - 1);
            setStage(route[stageIndex - 1]);
          }}
        >
          <img src={arrow} alt="arrow" />
          <span>{t("Back")}</span>
        </div>
      )}
      {route &&
        route.map((item: string, index: number) => (
          <img
            className="mobile-route-item"
            src={activeIndex[index].includes(step) ? green : grey}
            alt=""
            key={index}
          />
        ))}
      <div
        className="logout"
        onClick={async () => {
          await dispatch(logoutAsync());
          navigate("/business/signin");
        }}
      >
        <img src={exit} alt="logout" />
      </div>
    </div>
  );
};

export default MobileProgresBar;
