import React, {useContext, useState} from 'react'
import {useTranslation} from 'react-i18next';
import {postReview} from '../../api/clientReviewApi';
import {ClientContext} from '../../service/ClientContext';

import '../../assets/styles/default.css';


export const getCookie = () => {
    try {
        const cookies = document.cookie.split(';').map(cookie => cookie.trim());
        if (cookies) {
            let target = cookies.find(item => item.indexOf('authData') >= 0)
            let obj = target ? target.split('authData=')[1] : null
            return obj ? JSON.parse(obj) : null
        }

        return null;
    } catch (e) {
        return null;
    }
};

const PoorReview = ({ showForm, showModal }: any) => {
    const { t , i18n} = useTranslation();
    const closeIcon = require('../../assets/close.png')
    const starIcon = require('../../assets/star.png')
    const goldStarIcon = require('../../assets/gold-star.png')

    const { spot_id, companyName, setReviewsCurrentPage } = useContext<any>(ClientContext)

    const [rate, setRate] = useState<number | null>(null)

    const [isSubmitted, setIsSubmitted] = useState(false);

    const [inputs, setInputs] = useState({
        email: { value: '', isRequired: true },
        userName: { value: '', isRequired: true },
        comment: { value: '', isRequired: true },
    })

    const rating = [1, 2, 3, 4, 5]

    const handleInputChange = (event: any): void => {
        const { name, value } = event.target;
        setInputs((prevInputs: any) => ({
            ...prevInputs,
            [name]: {
                ...prevInputs[name],
                value,
            }
        }));
    };

    const handlePost = (e: any) => {
        e.preventDefault();

        setIsSubmitted(true);
        const isRequiredFieldEmpty = Object.values(inputs).some((field) => {
            return field.isRequired && !field.value;
        });

        if (isRequiredFieldEmpty || !rate) {
            console.log('Поля не заполнены')
        } else {
            postReview(spot_id, inputs.comment.value, rate, inputs.email.value, inputs.userName.value, i18n.language);
            setReviewsCurrentPage(0);
            showForm(false)
            showModal(true)
            localStorage.removeItem('review')
        }
    }


    return (
        <div className='review-poor-page'>
            <div className="review-close-block">
                <img src={closeIcon} alt=""
                    onClick={() => { showForm(false); localStorage.removeItem('review') }}
                />
            </div>
            <div className="review-form">
                <div className="review-form-description">
                    <h3 className='fw-bold'>{t("Help")} <span className="green-text">{companyName}</span> {t("  get better!")}</h3>
                    <p style={{whiteSpace: "pre-line"}}>
                        {t(`Tell us what you don't like and what could be improved`)}
                    </p>
                </div>
                <div className="input-block input-block_required">
                    <input
                        type="email"
                        placeholder='E-Mail'
                        name='email'
                        value={inputs.email.value}
                        onChange={handleInputChange}
                        className={isSubmitted && !inputs.email.value ? 'invalid' : ''}
                    />
                </div>
                <div className="input-block input-block_required">
                    <input
                        type="text"
                        placeholder={t('Full Name')}
                        name='userName'
                        value={inputs.userName.value}
                        onChange={handleInputChange}
                        className={isSubmitted && !inputs.userName.value ? 'invalid' : ''}
                    />
                </div>
                <div className="input-block input-block_required">
                    <textarea
                        name="comment"
                        value={inputs.comment.value}
                        id="comment"
                        cols={30}
                        rows={5}
                        placeholder={t('Leave Your Comment')}
                        onChange={handleInputChange}
                        className={isSubmitted && !inputs.comment.value ? 'invalid' : ''}
                    ></textarea>
                </div>
                <div className={isSubmitted && !rate ? 'd-flex align-items-center justify-content-around mb-3 rating-block invalid' : 'd-flex align-items-center justify-content-around mb-3 rating-block'}>
                    {rating.map((item, index) =>
                        <img
                            className={'rate-star'}
                            key={index}
                            src={rate && item <= rate ? goldStarIcon : starIcon}
                            alt=""
                            onClick={() => setRate(item)}
                        />
                    )}
                </div>
                <button className='submit-review active-btn'
                    onClick={handlePost}
                >
                    {t("Send feedback")}
                </button>
            </div>
        </div>
    )
}

export default PoorReview