import React, { useState } from 'react'

const SelectOption = ({ item, callback, }: any) => {

    return (
        <div
            style={{
                backgroundColor: 'rgba(246, 246, 246, 1)',
                padding: '12px 0',
                cursor: 'pointer'
            }}
            onClick={() => callback(item)}
        >
            <p className="m-0 p-0">
                {item.name}
            </p>
        </div>
    )
}

const SelectField = ({ options, currentValue, getValue }: any) => {

    const [shown, setShown] = useState(false)

    const clickHandler = (item: any) => {
        getValue(item);
        setShown(!shown);
    }

    return (
        <div
            style={{
                backgroundColor: 'rgba(246, 246, 246, 1)',
                padding: '12px',
                borderRadius: '12px',
                marginBottom: '12px',
                color: 'black'
            }}
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'relative' }}
                onClick={() => setShown(!shown)}
            >
                <p className='m-0 p-0 w-100' style={{ cursor: 'pointer' }}>{currentValue}</p>
                {
                    shown ?
                        <div style={{
                            position: 'absolute',
                            maxHeight: '120px',
                            backgroundColor: 'rgba(246, 246, 246, 1)',
                            padding: '12px',
                            borderRadius: '12px',
                            marginBottom: '12px',
                            width: '105%',
                            top: '30px',
                            left: '-12px',
                            overflowY: 'scroll',
                        }}>
                            {
                                options && options.map((item: string, index: number) => (
                                    <SelectOption item={item} key={index} callback={clickHandler} />
                                ))
                            }
                        </div>
                        : null
                }
                {/* <select
                    style={{
                        border: 'none',
                        outline: 'none',
                        backgroundColor: 'transparent',
                        width: '100%'
                    }}
                    value={currentValue}
                    onChange={(e) => getValue(e.target.value)}
                >
                    {
                        options && options.map((item: any, index: number) => (
                            <option key={index} value={item} style={{ padding: '12px 0' }}>
                                {item}
                            </option>
                        ))
                    }
                </select> */}
            </div>
        </div>
    )
}

export default SelectField