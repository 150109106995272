import React from "react";
import {app_urls} from "../routes/urls";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const useBusinessNav = () => {
  const { t } = useTranslation();
  const { dashboard_id, company_id: company_params_id } = useParams();
  const navstar = require("assets/navstar.png");
  const invoices = require("assets/invoices.png");
  const printer = require("assets/template.png");
  const setting = require("assets/setting.png");

  const company_local_id = localStorage.getItem('company_id') || "";
  const company_id = company_params_id  || company_local_id;

  const dashboard_urls = [
    {
      name: "Heroboards",
      url: app_urls.business.spots(company_id),
      icon: null,
      componentIcon: <ArrowBackIosIcon className={'nav-tab-icon'}/>
    },
    {
      name: t("Reviews"),
      url: app_urls.business.reviews(dashboard_id),
      icon: navstar,
      componentIcon: null
    },
    {
      name: t("Templates"),
      url: app_urls.business.print_templates(dashboard_id),
      icon: printer,
      componentIcon: null
    },
    {
      name: t("Email signature"),
      url: app_urls.business.email_signature(dashboard_id),
      icon: setting,
      componentIcon: null
    },
    {
      name: t("Settings"),
      url: app_urls.business.settings(dashboard_id),
      icon: setting,
      componentIcon: null
    },
    {
      name: t("Billing & Plan"),
      url: app_urls.business.billing(dashboard_id),
      icon: invoices,
      componentIcon: null
    }
  ]

  const board_urls = [
    {
      name: "Companies",
      url: app_urls.business.companies,
      icon: null,
      componentIcon: <ArrowBackIosIcon className={'nav-tab-icon'}/>
    },
    {
      name: "Heroboards",
      url: app_urls.business.spots(company_id),
      icon: navstar,
      componentIcon: null
    },
    {
      name: "Billing",
      url: app_urls.business.billings(company_id),
      icon: invoices,
      componentIcon: null
    }
  ]

  const company_urls = [
    {
      name: "Companies",
      url: app_urls.business.companies,
      icon: invoices,
      componentIcon: null
    }
  ]

  function getSideBarUrl() {
    if (document.location.href.includes('heroboard')) {
      return dashboard_urls
    } else if (document.location.href.includes('companies')) {
      return company_urls
    } else {
      return board_urls
    }
  }

  const sideBarUrl = getSideBarUrl()

  const current = [...sideBarUrl].find(item => document.location.href.includes(item.url))

  return { sideBarUrl, current }
}

export default useBusinessNav;
