import React, { SVGAttributes } from 'react';

interface GoodIconSvgProps extends SVGAttributes<HTMLOrSVGElement> {
    id?: string;
    className?: string;
    width?: number;
    height?: number;
}

const GoodIconSvg: React.FC<GoodIconSvgProps> = ({ id = '', className = '', width = 86, height = 113, ...props}) => {
    return (
        <svg id={id} className={className} width={width} height={height} viewBox="0 0 86 87" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2785_5587)">
            <path d="M43 0.300781C31.5957 0.300781 20.6585 4.83113 12.5944 12.8952C4.53034 20.9593 0 31.8965 0 43.3008C0 54.7051 4.53034 65.6423 12.5944 73.7064C20.6585 81.7704 31.5957 86.3008 43 86.3008C54.4043 86.3008 65.3415 81.7704 73.4056 73.7064C81.4697 65.6423 86 54.7051 86 43.3008C86 31.8965 81.4697 20.9593 73.4056 12.8952C65.3415 4.83113 54.4043 0.300781 43 0.300781Z" fill="#59C857"/>
            <path d="M65.3707 39.7425C66.3167 40.4037 67.1875 41.0594 67.1875 43.3653C67.1875 45.6765 65.9566 46.6118 64.6398 47.2622C65.1772 48.1383 65.3492 49.1972 65.1181 50.1969C64.7043 52.0459 63.0111 53.481 61.5061 53.9057C62.1565 54.9484 62.3608 55.975 61.5868 57.2382C60.5924 58.8238 59.727 59.4258 55.9 59.4258H40.3125C35.002 59.4258 32.25 56.491 32.25 54.0508V41.5002C32.25 34.8889 40.1351 29.272 40.1351 24.6764L39.5654 18.952C39.5385 18.6027 39.6084 17.748 39.8771 17.5008C40.3071 17.0762 41.495 16.4258 43.2902 16.4258C44.462 16.4258 45.2414 16.6462 46.1605 17.0869C49.2834 18.5758 50.095 22.3437 50.095 25.3752C50.095 26.8318 47.8698 31.1963 47.5688 32.7067C47.5688 32.7067 52.2289 31.6747 57.6684 31.637C63.3713 31.6048 67.0692 32.6583 67.0692 36.1628C67.0692 37.5657 65.8921 38.9739 65.3707 39.7425ZM19.35 37.9258H23.65C24.5053 37.9258 25.3256 38.2656 25.9304 38.8704C26.5352 39.4752 26.875 40.2955 26.875 41.1508V61.5758C26.875 62.4311 26.5352 63.2514 25.9304 63.8562C25.3256 64.461 24.5053 64.8008 23.65 64.8008H19.35C18.4947 64.8008 17.6744 64.461 17.0696 63.8562C16.4648 63.2514 16.125 62.4311 16.125 61.5758V41.1508C16.125 40.2955 16.4648 39.4752 17.0696 38.8704C17.6744 38.2656 18.4947 37.9258 19.35 37.9258Z" fill="white"/>
            </g>
            <defs>
            <clipPath id="clip0_2785_5587">
            <rect width="86" height="86" fill="white" transform="translate(0 0.300781)"/>
            </clipPath>
            </defs>
        </svg>
    );
}

export default GoodIconSvg;
