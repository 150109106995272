import React, { useState } from "react";
import ModalProfile from "./modalProfile/ModalProfile";
import useBusinessNav from "../../../../hooks/useBusinessNav";
import {app_urls} from "../../../../routes/urls";

const MobileTopBar = ({ name, rate, show, setShow }: any) => {
  const ratingArray = rate ? new Array(Math.floor(rate)).fill(0) : [];
  const {current} = useBusinessNav()

  const staricon = require("assets/gold-star.png");
  const logo = require("assets/logo.png");
  const noticon = require("assets/notification-icon.png");
  const redround = require("assets/redround.png");
  const accountIcon = require("assets/avatar.png");

  rate = rate ? parseFloat(rate).toString().slice(0, 3) : 0;

  return (
    <div className="mobile-topbar-container" style={{ position: "relative" }}>
      <ModalProfile shown={show} setShow={setShow} />
      <div className="mobile-topbar">
        <img src={logo} alt="" style={{ width: "100px" }} />
        <div className="mobile-topbar-right">
          <div className="profile-menu d-flex align-items-center">
            <span className="me-2" style={{ color: "silver" }}>
              My profile
            </span>
            <img
              src={accountIcon}
              alt=""
              style={{ maxWidth: "32px" }}
              onClick={() => setShow(!show)}
            />
          </div>
          {/* <div className="notification">
                        <img src={noticon} alt="" style={{ maxWidth: '18px' }} />
                        <img src={redround} className='indicator' alt="" style={{ maxWidth: '10px' }} />
                    </div> */}
        </div>
      </div>
      <div className="rate-infoblock pt-4">
        <h3 className="fw-bold">{current?.name}</h3>
        {current?.url === app_urls.business.reviews() ? (
          <div className="account-rating">
            <div className="d-flex">
              {ratingArray &&
                ratingArray.map((item, index) => (
                  <img
                    src={staricon}
                    key={index}
                    alt=""
                    style={{width: "20px", height: "20px"}}
                  />
                ))}
            </div>
            <p className="m-0 fw-bold">Average rating: {`${rate || 0}/5`}</p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default MobileTopBar;
