import React, { useContext, useState } from "react";
import { AppContext } from "service/AppContext";
import { getCookie } from "pages/client/PoorReview";
import { copyToClipboard } from "utils/copyToClipboard";
import "./MobileNavBottom.scss";
import useBusinessNav from "../../../../../hooks/useBusinessNav";
import {useNavigate} from "react-router-dom";

const MobileNavBottom = () => {
  const download = require("assets/download.png");
  const copy = require("assets/copy.png");
  const navigate = useNavigate();

  const {sideBarUrl, current} = useBusinessNav()

  const { userInfo } = useContext<any>(AppContext);
  const qr = userInfo?.spot_qr_code ? userInfo?.spot_qr_code : "#" 
  
  const protocol = window.location.protocol;
  const host = window.location.host;
  const linkToCopy = userInfo ? `${protocol}//${host}/${userInfo.spot_slug}` : "";

  const [isCopied, setIsCopied] = useState(false);

  return (
    <div className="mobile-navbottom">
      <div className="links">
        <div className="bottom-links">
          <a href={qr} target="_blank">
            <img src={download} alt="" className="" />
            Download your QR-code
          </a>
          <div style={{ position: "relative", display: "inline-block" }}>
            <a
              href="#"
              onClick={() => {
                copyToClipboard(linkToCopy);
                setIsCopied(true);
                setTimeout(() => setIsCopied(false), 2000);
              }}
            >
              <img src={copy} alt="" className="" />
              Copy your link
            </a>
            {isCopied && <div className="copy-confirmation">{"Copied"}</div>}
          </div>
        </div>
      </div>

      <div className="navbottom-tabs">
        {sideBarUrl.map((item, index) => (
          <div
            key={index + "nav-tab"}
            className={item.url === current?.url ? "mobilenav-tab mobile-tab-active" : "mobilenav-tab"}
            onClick={() => navigate('/business' + item.url)}
          >
            <img src={item.icon} alt="" className="mobilenav-tab-icon" />
            <p className="m-0">{item.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MobileNavBottom;
