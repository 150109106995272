import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import InputField from "components/uiKit/InputField";
import SelectField from "components/uiKit/SelectField";
import { ServiceFunctions } from "service/serviceFunctions";
import { AppContext } from "service/AppContext";
import {useTranslation} from "react-i18next";

const ModalEditBilling = ({ close, open, show }: any) => {
  const { accessToken } = useContext<any>(AppContext);
  const { t } = useTranslation();

  const [userEmail, setUserEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [fullName, setFullName] = useState<string | null>(null);
  const getFullName = (e: any) => {
    setFullName(e.target.value);
  };

  const [companyName, setCompanyName] = useState<string | null>(null);
  const getCompanyName = (e: any) => {
    setCompanyName(e.target.value);
  };

  const [invoice, setInvoice] = useState<string | null>(null);
  const getInvoiceAddress = (e: any) => {
    setInvoice(e.target.value);
  };

  const [postal, setPostal] = useState<string | null>(null);
  const getPostal = (e: any) => {
    setPostal(e.target.value);
  };

  const [city, setCity] = useState<string | null>(null);
  const getCity = (e: any) => {
    setCity(e.target.value);
  };

  let countries = require("../../../../service/countries.json");
  countries = countries
    ? countries.map(
        (item: {
          name: {
            common: any;
            "": any;
          };
          cca2: any;
        }) => ({ name: item.name.common, value: item.cca2 })
      )
    : [];
    
  countries.sort(function(a: any, b: any) {
    var nameA = a.name.toUpperCase();
    var nameB = b.name.toUpperCase();
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }
    return 0;
  });

  const [userCountry, setUserCountry] = useState<string | null>(null);
  const [userCountryName, setUserCountryName] = useState<string | null>(null);
  const getCountry = (item: any) => {
    setUserCountry(item.value?.toLowerCase());
    setUserCountryName(item.name);
  };

  const handleEmailChange = (event: any) => {
    setUserEmail(event.target.value);
  };

  const [pm, setPm] = useState<any>(null);
  useEffect(() => {
    if (accessToken) {
      ServiceFunctions.getPaymentMethod(accessToken?.replaceAll('"', "")).then(
        (data) => setPm(data)
      );
    }
  }, []);

  const validateEmail = () => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailPattern.test(userEmail)) {
      setEmailError("Invalid email address.");
    } else {
      setEmailError("");
    }
  };

  const resetValues = () => {
    setEmailError("");
    setUserEmail("");
    setInvoice(null);
    setPostal(null);
    setCity(null);
    setUserCountry(null);
    setUserCountryName(null);
    setCompanyName(null);
    setFullName(null);
  };

  const handleSubmit = (event: any) => {
    if (
      emailError ||
      !city ||
      !postal ||
      !userCountry ||
      !invoice ||
      !fullName ||
      !companyName ||
      !pm
    ) {
      event.preventDefault();
    } else {
      const obj = {
        payment_method_id: pm?.paymentMethod?.id,
        full_name: fullName,
        email: userEmail,
        postal_code: postal,
        city: city,
        country: userCountry,
        line1: invoice,
        company_name: companyName,
      };
      ServiceFunctions.updateBillingInfo(obj, accessToken?.replaceAll('"', ""));
      close();
      resetValues();
    }
  };

  return (
    <Modal
      show={show}
      onHide={close}
      backdrop="static"
      keyboard={false}
      style={{ borderRadius: "20px" }}
    >
      <Modal.Header closeButton>
        <Modal.Title
          style={{ paddingLeft: "18%", fontSize: "20px", fontWeight: "bold" }}
        >
          {t('Edit billing information')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <InputField
            title={t("Full Name")}
            required={true}
            onChange={getFullName}
          />
          <InputField
            title={t("Company name")}
            required={true}
            onChange={getCompanyName}
          />
          <InputField
            title={t("Email address to send invoices to")}
            type={"email"}
            required={true}
            onChange={handleEmailChange}
            validateChange={validateEmail}
            errorText={emailError}
          />
          <p className="mt-2 mb-2">{t('Billing address')}</p>
          <InputField
            title={t("Invoice address")}
            required={true}
            onChange={getInvoiceAddress}
          />
          <div className="d-flex justify-content-between">
            <InputField
              title={t("Postal code")}
              required={true}
              onChange={getPostal}
            />
            <div>&nbsp;</div>
            <InputField title={"City"} required={true} onChange={getCity} />
          </div>
          <SelectField
            title={"Country"}
            required={true}
            options={countries}
            currentValue={userCountryName || t("Select your country")}
            getValue={getCountry}
          />
        </div>
        <div className="d-flex justify-content-between pt-2">
          <button
            className="col"
            style={{
              backgroundColor: "transparent",
              border: "none",
              outline: "none",
              padding: "8px",
              color: "grey",
              fontWeight: "500",
            }}
            onClick={() => {
              close();
              resetValues();
            }}
          >
            {t('Cancel')}
          </button>
          <button
            className="col"
            style={{
              backgroundColor: "rgba(89, 200, 87, 1)",
              color: "white",
              fontWeight: "500",
              border: "none",
              outline: "none",
              padding: "8px",
              borderRadius: "12px",
            }}
            onClick={handleSubmit}
          >
            {t('Save changes')}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalEditBilling;
