import React, { useState } from "react";
import { getTemplate } from "../../../api/marketingApi";
import Spinner from 'react-bootstrap/Spinner';

import './PrintTemplateCard.scss';

const PrintTemplateCard = ({
  templateId,
  title,
  text,
  imageSrc,
  subtext,
  primaryLink,
  secondaryLink,
  orderLink,
  downloadLink,
  primeText,
  secondaryText,
  orderUrl
}: any) => {
  const [isLoading, setIsLoading] = useState(false)
  
  const downloadIcon = require("../../../assets/download-file.png");
  const extLink = require("../../../assets/ext-link.png");
  const extLinkGreen = require("../../../assets/ext-link-green.png");

  const getPdf = async () => {
    setIsLoading(true);  
    await getTemplate(downloadLink);
    setIsLoading(false); 
  };

  return (
    <div className="template-card">
      <img className="template-image" src={imageSrc} alt="bg img"/>
      <div className="template-content">
        <div>
          <p className="mb-2 template-content-title">{title}</p>
          <div className="template-text">
            <span>{text}</span>
          </div>
          <p className="grey-link mb-3" style={{fontSize: "14px"}}>
            {subtext ? subtext : null}
          </p>
        </div>
        <div className="d-flex mb-2 align-items-center a-group">
          <button
            // href={downloadLink || "#"}
            onClick={() => {
              getPdf();
            }}
            className="pdf"
          >
            <div className="pdf-icon-container">
              {isLoading ? (
                <Spinner id="spinner" animation="border" size="sm"/>
              ) : (
                <img
                  src={downloadIcon}
                  alt="download pdf"
                />
              )}
            </div>
            <span
              className="ml-2 green-text p-0 m-0"
              style={{fontWeight: "500"}}
            >
                PDF
              </span>
          </button>
          {/*{orderUrl && (*/}
          {/*  <a*/}
          {/*    href={orderUrl || "#"} className='order-now'*/}
          {/*    style={{*/}
          {/*      marginLeft: '8px'*/}
          {/*    }}*/}
          {/*  >*/}
          {/*      <span*/}
          {/*        className="green-text text-center"*/}
          {/*        style={{*/}
          {/*          fontWeight: "500",*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        Order now*/}
          {/*      </span>*/}
          {/*  </a>*/}
          {/*)}*/}
        </div>
        {primaryLink || secondaryLink ? (
          <div className="">
            {primaryLink && (
              <a href={primaryLink || "#"}
                 className="template-prime-link d-flex">
                <p className="m-0 p-0 me-2">{primeText}</p>
                <img src={extLink} alt="" style={{maxWidth: "24px"}}/>
              </a>
            )}
            {secondaryLink && (
              <a href={secondaryLink || "#"}
                 className="template-secondary-link d-flex">
                <p className="m-0 p-0 me-2">{secondaryText}</p>
                <img src={extLinkGreen} alt="" style={{maxWidth: "24px"}}/>
              </a>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PrintTemplateCard;
