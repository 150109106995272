import React from "react";
import {Avatar} from "@mui/material";

const ReviewItem = ({ data, companyName }: any) => {
  const starIcon = require("../../../assets/gold-star.png");

  const ratingArray = new Array(data.rating).fill(0);

  return (
    <div className="review-item">
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <Avatar src={data.user_avatar} className={"me-2 user-img"}/>
          <div className="">
            <h4 className="review-name mb-0">{data.user_name}</h4>
            <p className="company-name">{companyName}</p>
          </div>
        </div>
        <div className="review-stars">
          {ratingArray &&
            ratingArray.map((item: number, index: number) => (
              <img
                src={starIcon}
                alt=""
                key={index}
                style={{ maxWidth: "15px" }}
              />
            ))}
        </div>
      </div>
      <p className="mt-2 comment" style={{wordBreak: 'break-all'}}>{data.comment}</p>
      <div className="d-flex justify-content-between">
        <div className="col comment-date">
          <span>
            {data &&
              data.created_at &&
              new Date(data.created_at).toLocaleString()}
          </span>
        </div>
        <div className="col"></div>
      </div>
    </div>
  );
};

export default ReviewItem;
