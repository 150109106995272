import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import LandingClient from "pages/client/LandingClient";
import LandingClientNew from "pages/client/LandingClientNew/LandingClientNew";
import TermsAndConditions from "pages/client/TermsAndConditions/TermsAndConditions";
import Imprint from "pages/client/Imprint/Imprint";
import HeroBoard from "pages/client/Heroboard";
import SignIn from "pages/client/SignIn";
import GoogleCallback from "containers/GoogleCallback";
import PrivacyPolicy from "../pages/client/TermsAndConditions/PrivacyPolicy";
import {app_urls} from "./urls";

const ClientsRoutes = () => {
    return (
        <Routes>
            <Route path={app_urls.client.terms} element={<TermsAndConditions/>}/>
            <Route path={app_urls.client.privacy} element={<PrivacyPolicy/>}/>
            <Route path={app_urls.client.imprint} element={<Imprint/>}/>
            <Route path={app_urls.client.root} element={<LandingClientNew/>}/>
            <Route path={app_urls.client.old} element={<LandingClient/>}/>
            <Route path={app_urls.client.client_page()} element={<LandingClientNew/>}/>
            <Route path={app_urls.client.heroboard()} element={<HeroBoard/>}/>
            <Route path={app_urls.client.sign} element={<SignIn/>}/>
            <Route path={app_urls.client.google_auth} element={<GoogleCallback/>}/>
            <Route path={'/signin'} element={<Navigate to={'/business' + app_urls.business.signin}/>}/>
            <Route path={'/login'} element={<Navigate to={'/business' + app_urls.business.signin}/>}/>
        </Routes>
    );
};

export default ClientsRoutes;
