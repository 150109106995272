import axios from "axios";

const key = process.env.REACT_APP_GOOGLE_API_KEY;

export const findAddresses = async (name: string) => {
  const response = await axios.post(
    `https://places.googleapis.com/v1/places:searchText`,
    JSON.stringify({
      textQuery: name,
    }),
    {
      headers: {
        "content-type": "application/json",
        "X-Goog-Api-Key": `${key}`,
        "X-Goog-FieldMask": "*",
      },
    }
  );
  return response.data;
};
