import { apiInstance } from "./api";

export const getCompanySpot = async (slug: string) => {
  const response = await apiInstance().get(`/api/company/${slug}`);
  return response.data;
};

export const sendSpotId = async (id: string, lang?: string) => {
    const response = await apiInstance().post(`/api/google/review`, {spot_id: id}, {params: {lang}});
    return response.data;
};

export const createCompany = async (companyData: any) => {
  if (companyData.question_ids) {
    companyData.question_ids = companyData.question_ids.filter(
      (id: any) => id !== null
    );
  }
  const response = await apiInstance().post(
    `/api/company/`,
    {
      ...companyData,
    },
    {
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
    }
  );
  return response.data;
};

export const updateCompany = async (companyData: any, companyId: number) => {
    const response = await apiInstance().patch(
      `/api/company/${companyId}`,
      companyData,
      {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
        },
      }
    );
    return response.data;
};

export const updateCompanySpot = async (companySpotData: any, slug: string) => {
    const response = await apiInstance().patch(
      `/api/spot/${slug}`,
      companySpotData,
      {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
        },
      }
    );
    return response.data;
};
