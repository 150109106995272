import Button from "components/uiKit/Button/Button";
import React from "react";
import { useTranslation } from "react-i18next";

const HeroHeader = ({ length }: any) => {
    const { t } = useTranslation();

    return (
      <div className="hero-header">
        <Button variant="back" >{t("Back")}</Button>
        
        <div className="hero-title col">
          <h4 className="fw-bold mb-0">Heroboard</h4>
          <p>{length} {length !== 1 ? t("Heroes") : t("Hero")}</p>
        </div>
        <div className="hero-empty col"></div>
      </div>
    );
  };

  export default HeroHeader;