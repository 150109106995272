import React, { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import InputField from "components/uiKit/InputField";
import { AppContext } from "service/AppContext";
import { ServiceFunctions } from "service/serviceFunctions";
import { getCookie } from "pages/client/PoorReview";
import { useAppSelector } from "app/hooks";
import { selectCompany, CounterSliceState } from "features/business/companySlice";
import {useTranslation} from "react-i18next";
import {apiInstance} from "../../../../api/api";

const langs: any = [
    { label: "English", value: 'en' },
    { label: "Deutsch", value: 'de' },    
];

const ManageProfileModal = ({ show, close }: any) => {
  const { accessToken, userInfo, setLanguage } = useContext<any>(AppContext);
  const company = useAppSelector(selectCompany) as CounterSliceState;
  const userData = getCookie();  
  const email = userData?.data?.email || company?.company?.email;
  const currentLanguage = userInfo?.lang || localStorage.getItem("language") || langs[0].value;
  const {t} = useTranslation();
  
  const [lang, setLang] = useState<string>(currentLanguage);
  // const [userEmail, setUserEmail] = useState("");
  // const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState({ currentPassword: '', newPassword: '', confirmPassword: '' });

  const changeLanguageAsync = (lang: string) => {
    apiInstance().patch('/api/user/', { lang })
  }
  const saveChanges = () => {
    localStorage.setItem("language", lang);
    setLanguage(lang)
    changeLanguageAsync(lang);
    close()
  };

//   const handleEmailChange = (event: any) => {
//     setUserEmail(event.target.value);
//   };

//   const validateEmail = () => {
//     const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

//     if (!emailPattern.test(userEmail)) {
//       setEmailError("Invalid email address.");
//     } else {
//       setEmailError("");
//     }
//   };

  const setNewPassword = (e: any) => {
      const name = e.target.name
      const value = e.target.value

      setPassword(state => ({...state, [name]: value}))
  } 

  const onChangePassword = () => {
      if (!password.currentPassword || !password.newPassword || !password.confirmPassword) {
            return alert('Invalid password provided')
      }
      if (password.newPassword !== password.confirmPassword) {
          return alert('Passwords do not match')
      }

      ServiceFunctions.updatePassword(
        { 
            old_password: password.currentPassword,
            new_password: password.newPassword,
            confirm_password: password.confirmPassword
        },
        accessToken
      )
      .then(res => {
          if (res) {
              alert('Your password has been changed successfully');
              setPassword({ currentPassword: '', newPassword: '', confirmPassword: '' })
              close()
          } else {
              alert('Something went wrong while changing the password')
          }
      })
      .catch((err) => {
        console.error(err)
        alert('Something went wrong while changing the password')
      })
  }

  return accessToken && email ? (
    <Modal
      show={show}
      onHide={close}
      backdrop="static"
      keyboard={false}
      style={{ borderRadius: "20px", zIndex: 9999999 }}
    >
      <Modal.Header closeButton>
        <Modal.Title
          style={{ paddingLeft: "18%", fontSize: "20px", fontWeight: "bold" }}
        >
            {t('Manage Your Account')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p className="fw-bold mb-1">Login management</p>
          <p className="m-0 mb-1 p-0 grey-link" style={{ fontSize: "14px" }}>
              {t('Manage your email address and password you use to log into Heroboard.')}
          </p>
        </div>
        <div>
          <InputField
            title="Email"
            type="email"
            name="email"
            defaultValue={email}
            required={true}
            // onChange={handleEmailChange}
            // validateChange={validateEmail}
            // errorText={emailError}
            disabled
          />
           <InputField
            title={t('Current password')}
            type="password"
            name="currentPassword"
            required={true}
            onChange={setNewPassword}
          />      
        </div>
        <p className="fw-bold">{t('Change password')}</p>
        <div>
          <InputField
            title={t('New password')}
            type="password"
            name="newPassword"
            required={true}
            onChange={setNewPassword}
          />
          <InputField
            title={t('Confirm password')}
            type="password"
            name="confirmPassword"
            required={true}
            onChange={setNewPassword}
          />
        </div>
        <div className="mt-2">
          <button
            className="col-6"
            style={{
              backgroundColor: "rgba(89, 200, 87, 1)",
              color: "white",
              fontWeight: "500",
              border: "none",
              outline: "none",
              padding: "8px",
              borderRadius: "12px",
            }}
            onClick={onChangePassword}
          >
              {t('Change password')}
          </button>
        </div>
        <div>
          <p className="fw-bold mb-1 mt-2">{t('Preferences')}</p>
          <p className="m-0 mb-1 p-0 grey-link" style={{ fontSize: "14px" }}>
              {t('Select which language you want to use in the app.')}
          </p>
        </div>

        <select
          style={{
            border: 'none',
            borderRadius: 12,
            outline: 'none',
            backgroundColor: 'rgb(246, 246, 246)',
            width: '100%',
            height: 63,
            padding: 12,
            margin: '10px 0px'
          }}
          value={lang}
          onChange={(e) => setLang(e.target.value)}
        >
          {langs && langs.map((item: any, index: number) => (
            <option key={index} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>

        <div className="d-flex justify-content-between pt-2">
          <button
            className="col"
            style={{
              backgroundColor: "transparent",
              border: "none",
              outline: "none",
              padding: "8px",
              color: "grey",
              fontWeight: "500",
            }}
            onClick={close}
          >
                {t('Cancel')}
          </button>
          <button
            className="col"
            style={{
              backgroundColor: "rgba(89, 200, 87, 1)",
              color: "white",
              fontWeight: "500",
              border: "none",
              outline: "none",
              padding: "8px",
              borderRadius: "12px",
            }}
            onClick={saveChanges}
          >
    {t('Save changes')}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  ) : null;
};

export default ManageProfileModal;
