import { apiInstance } from "./api";

export const getBusinessReviews = async (slug: string,page: number) => {
  const response = await apiInstance().get(`/api/business/review/${slug}`, {
    params: {
      page: page,
    },
  });
  return response.data;
};
