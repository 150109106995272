import { url } from "./config"
import {apiInstance} from "../api/api";

interface Question {
    id: number,
    type_name: string,
    text: string
}

interface Data {
    data: Question[] | null
}

interface Plan {
    id: number,
    name: string,
    price: string
}

interface Tariffs {
    data: Plan[] | null
}

interface Functions {
    getReviews: (slug: string, page?: number) => Promise<any>;
    getInvoices: (token: string, page?: number) => Promise<any[]>;
    // getUserInfo: (token: string) => Promise<any[]>;
    logout: () => Promise<any[]>;
    postReview: (company_id: number, comment: string, rating: number, token: string) => Promise<any[]>;
    getQuestions: (lang: string) => Promise<Data | null>;
    getTariffs: (lang: string) => Promise<Tariffs | null>;
    createCompany: (data: object, token: string) => Promise<any[]>;
    updateCompany: (data: object, token: string, companyId: number) => Promise<any[]>;
    getPaymentMethod: (token: string) => Promise<any[]>;
    updatePassword: (obj: object, token: string) => Promise<boolean>;
    updateBillingInfo: (obj: object, token: string) => Promise<any[]>;
    getTariff: (id: number) => Promise<any>;
    getBillingPortal: (return_url: string) => Promise<any>;
}


export const ServiceFunctions: Functions = {

    getReviews: async (slug: string, page?: number) => {
        const res = await fetch(`${url}/api/review/${slug}?page=${page}`, {
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })
        const data = await res.json()
        return data
    },

    getInvoices: async (token: string, page?: number) => {
        const res = await fetch(`${url}/api/invoice?page=${page}`, {
            headers: {
                'content-type': 'application/json',
                'authorization': 'Bearer ' + token
            },
            method: 'GET'
        })
        const data = await res.json()
        return data
    },

    // getUserInfo: async (token: string) => {
    //     const res = await fetch(`${url}/api/user/`, {
    //         headers: {
    //             'accept': 'application/json',
    //             'content-type': 'application/json',
    //             'authorization': 'Bearer ' + token
    //         },
    //         method: 'GET'
    //     })
    //     const data = await res.json()
    //     return data
    // },

    postReview: async (company_id: number, comment: string, rating: number, token: string) => {
        const res = await fetch(`${url}/api/review`, {
            headers: {
                'content-type': 'application/json',
                'accept': 'application/json',
                'authorization': `Bearer ${token}`
            },
            method: 'POST',
            body: JSON.stringify({
                company_id,
                comment,
                rating
            })
        })
        const data = await res.json()
        return data
    },

    logout: async () => {
        const res = await fetch(`${url}/api/auth/logout`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            }
        })
        const cookies = document.cookie.split(";");

        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
        document.location.reload()
        const data = await res.json()
        return data
    },

    getQuestions: async (lang: string) => {
        const res = await fetch(`${url}/api/question/?lang=${lang}`, {
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })
        const data = await res.json()
        return data
    },

    getTariffs: async (lang: string) => {
        const {data} = await apiInstance().get(`${url}/api/tariff/?lang=${lang}`)
        return data
    },

    createCompany: async (data: object, token: string) => {
        const res = await fetch(`${url}/api/company`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'authorization': `Bearer ${token}`,
                'accept': 'application/json'
            },
            body: JSON.stringify(data)
        })
        const result = await res.json()
        return result
    },

    updateCompany: async (data: object, token: string, companyId: number) => {
        const res = await fetch(`${url}/api/company/${companyId}`, {
            method: 'PATCH',
            headers: {
                'content-type': 'application/json',
                'authorization': `Bearer ${token}`,
                'accept': 'application/json'
            },
            body: JSON.stringify(data)
        })
        const result = await res.json()
        return result
    },

    getPaymentMethod: async (token: string) => {
        const res = await fetch(`${url}/api/payment-method/`, {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
                'authorization': `Bearer ${token}`,
                'accept': 'application/json'
            },
        })
        const result = await res.json()
        return result
    },

    updatePassword: async (obj: object, token: string) => {
        const res = await fetch(`${url}/api/auth/change-password`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'authorization': `Bearer ${token}`,
                'accept': 'application/json'
            },
            body: JSON.stringify(obj)
        })          
        return res?.status === 200
    },

    updateBillingInfo: async (obj: object, token: string) => {
        const res = await fetch(`${url}/api/payment-method/billing`, {
            method: 'PATCH',
            headers: {
                'content-type': 'application/json',
                'authorization': `Bearer ${token}`,
                'accept': 'application/json'
            },
            body: JSON.stringify(obj)
        })
        const result = await res.json()
        return result
    },

    getTariff: async (id: number) => {
        const {data} = await apiInstance().get(`${url}/api/tariff/${id}`)
        return data
    },

    getBillingPortal: async (return_url: string) => {
        const {data} = await apiInstance().post(`${url}/api/billing-portal`, {
            return_url
        })
        return data
    }


}