import React, { useState} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, Link } from "react-router-dom";
import Button from "components/uiKit/Button/Button";

import styles from './BusinessSignIn.module.scss'
import SelectLanguage from "../../../../components/businessAuth/SelectLanguage";
import {apiInstance} from "../../../../api/api";

function BusinessForgot() {
  const { t } = useTranslation();
  const logo = require("assets/logo.png");

  const navigate = useNavigate();

  const [userEmail, setUserEmail] = useState("");
  const [backendError, setBackendError] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);

  const handleEmailChange = (event: any) => {
    setBackendError("");
    setUserEmail(event.target.value);
  };

  const onSubmit = (e: any) => {
    apiInstance().post("/api/auth/forgot-password", {email: userEmail}).then(
      (response) => {
        setIsEmailSent(true)
      }
    ).catch((error) => {
      setBackendError(error.response.data.message)
    }
    )

  };

  return (
    <div className={styles.signRedirect}>
      <h1>{t("Forgot Password")}</h1>
      <img src={logo} alt="" style={{maxWidth: "100px"}}/>
      <p>{t("Enter your email address and we will send you a link to reset your password.")}</p>
      <input
        type="email"
        placeholder={t('Email address')}
        name="email"
        value={userEmail}
        onChange={handleEmailChange}
      />
      {isEmailSent && <p style={{color: "green", textAlign: "center"}}>{t("Email sent successfully!")}</p>}
      {backendError && <p style={{color: "red", textAlign: "center"}}>{backendError}</p>}

      <div className={styles.bottomContainer}>
        <Button onClick={onSubmit}>
          {t("Reset Password")}
        </Button>
        <div className="" style={{padding: "10px", textAlign: "center"}}>
          <Link to='/terms' className="grey-link">{t("By continuing you agree to our Terms and Privacy Policy.")}</Link>
        </div>
      </div>
      <SelectLanguage/>
    </div>
  );
}

export default BusinessForgot;
