import React, { useContext } from 'react';
import { AppContext } from '../../service/AppContext';

import './ModalSentReview.scss';
import {useTranslation} from "react-i18next";

const ModalSentReview = () => {
    const check = require('../../assets/checkmark.png');
    const { setShown } = useContext<any>(AppContext);
    const {t} = useTranslation();

    return (
        <div className='modal-screen'>
            <div className="modal-pane">
                <img src={check} alt="check" />
                <h4 className='fw-bold'>{t('Thank you for your Review!')}</h4>
                {/*<h4 className='fw-bold'>{t('Your application is accepted!')}</h4>*/}
                <br/>
                {/*<p>{t('We will contact you shortly.')}</p>*/}

                <button className='submit-review active-btn'
                    onClick={() => { setShown(false) }}
                >Ok</button>
            </div>
        </div>
    )
}

export default ModalSentReview