import React, { useEffect } from "react";
import { getTemplatesList } from "api/marketingApi";
import PrintTemplateCard from "components/uiKit/printTemplateCard/PrintTemplateCard";

import "./PrintTemplates.scss";
import {Tab, Tabs} from "@mui/material";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {apiUrl} from "../../../../../api/api";

const PrintTemplates = () => {
  const [templates, setTemplates] = React.useState<any>([]);
    const { dashboard_id } = useParams()
  const [currentTemplate, setCurrentTemplate] = React.useState<'digital' | 'print'>('digital');
  const {t} = useTranslation();

  useEffect(() => {
    getTemplatesList().then((data) => {
      setTemplates(data.data);
    });
  }, []);

  return (
    <div className="business-printable">
      <Box sx={{ borderBottom: 1, borderColor: 'divider', margin: '6px 20px' }}>
        <Tabs value={currentTemplate} onChange={(_, value) => setCurrentTemplate(value)}
              textColor="inherit"
        >
          <Tab sx={{fontSize: "18px"}} label={t("Digital")} value={'digital'} />
          <Tab sx={{fontSize: "18px"}} label={t("Print")} value={'print'} />
        </Tabs>
      </Box>

      <div className="template-wrapper">
        {templates &&
          templates[currentTemplate]?.map((item: any, index: number) => (
            <PrintTemplateCard
              key={item.id + index}
              templateId={item.id}
              title={item.name}
              text={item.description}
              imageSrc={item.image}
              downloadLink={`${apiUrl}/api/template/${item.id}/${dashboard_id}`}
              orderUrl={item.checkout_url}
            />
          ))}
      </div>
    </div>
  );
};

export default PrintTemplates;
