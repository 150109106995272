import React from "react";

type GoalItemProps = {
    onClick: (value: number) => void
    label: string
    id: number
}

function GoalItem ({onClick, label, id}:GoalItemProps) {
    return <label className="w-100" style={{cursor: "pointer"}}>
        <div className="goal-item">
            <input
                style={{cursor: "pointer"}}
                type="radio"
                name={"radio"}
                id={String(id)}
                onClick={() => onClick(id)}
            />
            {label}
        </div>
    </label>
}

export default GoalItem;