import React, {useContext} from "react";
import Dropdown from "react-bootstrap/Dropdown";
import {useTranslation} from "react-i18next";
import {AppContext} from "../../service/AppContext";

const lngs: any = {
  en: { nativeName: "English" },
  de: { nativeName: "Deutsch" },
};

function SelectLanguage () {
  const { language, setLanguage } = useContext<any>(AppContext);

  return <Dropdown>
    <Dropdown.Toggle variant="" id="dropdown-basic" style={{zIndex: 9999999, padding: 0}}>
      {lngs[language]?.nativeName}
    </Dropdown.Toggle>

    <Dropdown.Menu>
      {Object.keys(lngs).map((lng) => (
        <Dropdown.Item
          key={lng}
          onClick={() => {
            localStorage.setItem("language", lng);
            localStorage.removeItem("review");
            setLanguage(lng);
          }}
        >
          {lngs[lng].nativeName}
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  </Dropdown>
}

export default SelectLanguage;