import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-bootstrap/Dropdown'
import { AppContext } from '../service/AppContext'
import Logo from './Logo'


const lngs: any = {
  en: { nativeName: 'English' },
  de: { nativeName: 'Deutsch' }
};

const TopBar = () => {
    const login = require('../assets/login-icon.png')

    const dropdownIcon = require('../assets/dropdown.png')

    const navigate = useNavigate()

    const languageList = ['English', 'German']
    const { t, i18n } = useTranslation();
    const { authData, language, setLanguage } = useContext<any>(AppContext)


    return (
        <div className='topbar'>
            <div className="container topbar-content">
                <Logo />              
                <div className="log-in-out"
                // onClick={() => {
                //     authData ?
                //         ServiceFunctions.logout()
                //         :
                //         navigate('/sign')
                // }}
                >
                    {/* <p>
                        {
                            authData ?
                                'Log out'
                                : 'Log in'
                        }
                    </p> */}

                    <Dropdown>
                        <Dropdown.Toggle variant="" id="dropdown-basic" style={{ zIndex: 9999999 }}>
                            {lngs[language]?.nativeName}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {/* <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item> */}
                            {
                              Object.keys(lngs).map((lng) => (
                                    <Dropdown.Item key={lng}
                                        onClick={() => {
                                            localStorage.setItem('language', lng)
                                            localStorage.removeItem('review')
                                            setLanguage(lng)
                                            i18n.changeLanguage(lng)
                                        }}
                                    >
                                      {lngs[lng].nativeName}
                                    </Dropdown.Item>
                                ))
                            }
                        </Dropdown.Menu>
                    </Dropdown>

                    {/* <img src={dropdownIcon} id='log-icon' alt="" /> */}
                </div>
            </div>
        </div>
    )
}

export default TopBar