import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./AddAddressForm.scss";
import { findAddresses } from "api/findAddresses";
import AutoCompleteAddress from "./AutoComplete";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const AddAddressForm = ({ progress, setProgress, setStage, setStep }: any) => {
  const { t } = useTranslation();
  interface Address {
    displayName: {
      text: string;
    };
    formattedAddress: string;
    id: string;
    googleMapsUri?: string;
  }

  const [target, setTarget] = useState<Address | null>(null);

  const [addresses, setAddresses] = useState<Address[] | null>(null);
  const [showList, setShowList] = useState<boolean>(false);

  const [name, setName] = useState<string | null>(null);
  // useEffect(() => {
  //   name && name.length > 2
  //     ? findAddresses(name).then((data) => (data ? setAddresses(data.places) : setAddresses(null)))
  //     : console.log();
  //   addresses ? setShowList(true) : setShowList(false);
  // }, [name]);

  // const ref: any = useRef(null);

  // useEffect(() => {
  //   ref.current.value = "";

  //   if (ref && target?.displayName) {
  //     ref.current.value = target?.displayName.text;
  //   }
  // }, [target?.displayName.text]);

  const onSetPlace = (place: any) => {
    console.log(place);
    setTarget(place);
  }

  return (
    <div className="goals-wrapper">
      <h3 className="text-center fw-bold">
        {t("Let’s find the address(es) of your business(es) next")}
      </h3>
      <p className="text-center">
        {t(
          "If you have several business locations, you can add them one by one. Each added location will add to the total price."
        )}
      </p>
      <div className="address-div ps-2 pe-2">
        <AutoCompleteAddress onSetPlace={onSetPlace}/>
        {target?.formattedAddress && (
          <div className="added-address">
            <span className="label">{t("LOCATIONS TO BE ADDED:")}</span>

            <div className="address-block">
              <div className="content">
                {target?.displayName.text + ", " + target?.formattedAddress}
              </div>

              <svg
                onClick={() => {
                  setTarget(null);
                  setShowList(false);
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
              >
                <path
                  d="M12.2084 11.0003L17.6084 5.60887C17.7698 5.44747 17.8605 5.22856 17.8605 5.0003C17.8605 4.77204 17.7698 4.55313 17.6084 4.39173C17.447 4.23032 17.2281 4.13965 16.9998 4.13965C16.7716 4.13965 16.5526 4.23032 16.3912 4.39173L10.9998 9.79173L5.60838 4.39173C5.44698 4.23032 5.22807 4.13965 4.99981 4.13965C4.77155 4.13965 4.55264 4.23032 4.39124 4.39173C4.22983 4.55313 4.13916 4.77204 4.13916 5.0003C4.13916 5.22856 4.22983 5.44747 4.39124 5.60887L9.79124 11.0003L4.39124 16.3917C4.3109 16.4714 4.24713 16.5662 4.20362 16.6707C4.1601 16.7751 4.1377 16.8871 4.1377 17.0003C4.1377 17.1135 4.1601 17.2255 4.20362 17.3299C4.24713 17.4344 4.3109 17.5292 4.39124 17.6089C4.47092 17.6892 4.56572 17.753 4.67017 17.7965C4.77462 17.84 4.88666 17.8624 4.99981 17.8624C5.11296 17.8624 5.22499 17.84 5.32945 17.7965C5.4339 17.753 5.5287 17.6892 5.60838 17.6089L10.9998 12.2089L16.3912 17.6089C16.4709 17.6892 16.5657 17.753 16.6702 17.7965C16.7746 17.84 16.8867 17.8624 16.9998 17.8624C17.113 17.8624 17.225 17.84 17.3294 17.7965C17.4339 17.753 17.5287 17.6892 17.6084 17.6089C17.6887 17.5292 17.7525 17.4344 17.796 17.3299C17.8395 17.2255 17.8619 17.1135 17.8619 17.0003C17.8619 16.8871 17.8395 16.7751 17.796 16.6707C17.7525 16.5662 17.6887 16.4714 17.6084 16.3917L12.2084 11.0003Z"
                  fill="#E0E0E0"
                />
              </svg>
            </div>
          </div>
        )}
      </div>

      <div className="bottom-block">
        <div style={{ padding: "0 20px", width: "600px" }} className="text-center">
          <button
            onClick={(e) => {
              if (target) {
                setProgress({
                  ...progress,
                  company_name: target.displayName.text,
                  spot_address: target.formattedAddress,
                  spot_id: target.id,
                  spot_link: "https://search.google.com/local/writereview?placeid=" + target.id, //target.googleMapsUri,
                  spot_slug: target?.displayName?.text ?
                  target?.displayName?.text
                   .replace(/[^a-zA-Z\s]/g, "")
                   .replaceAll(" ", "_")
                   .toLowerCase() :
                  ''
                });
                localStorage.setItem(
                  "regData",
                  JSON.stringify({
                    ...progress,
                    company_name: target.displayName.text,
                    spot_address: target.formattedAddress,
                    spot_id: target.id,
                    spot_link: "https://search.google.com/local/writereview?placeid=" + target.id, //target.googleMapsUri,
                    spot_slug: target?.displayName?.text ?
                    target?.displayName?.text
                     .replace(/[^a-zA-Z\s]/g, "")
                     .replaceAll(" ", "_")
                     .toLowerCase() :
                    ''
                  })
                );
                setStage("Set up review forest");
                setStep(4);
              } else e.preventDefault();
            }}
            className={target ? "submit-review active-btn-green" : "submit-review"}
            style={{ width: "368px" }}
          >
            {t("Connect")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddAddressForm;
