import React from "react";
import {Navigate, useNavigate} from "react-router-dom";
import {setCookie} from "../../utils/setCookie";

function AuthTokenComponent() {
    const navigate = useNavigate()

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get('access_token');

    if (!token) {
        return <Navigate to={'/'} />
    } else {
        setCookie("accessToken", token, 1);
        document.location.href = '/business/dashboard';
    }

    return <div>
        processing...
    </div>;
}

export default AuthTokenComponent;