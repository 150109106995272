import React, { useRef, useState } from 'react'

const InputField = ({ title, type, required, disabled, defaultValue, maxLength, expiry, cardNumber, onChange, validateChange, errorText, onKeyDown, ...rest }: any) => {

    const closeIcon = require('../../assets/close-grey.png')

    const ref = useRef<any>()

    const clearOnClick = (ref: any) => {
        ref.current.value = ''
        setState('')
    }

    const [state, setState] = useState<string | null>(null)

    const handleChange = (e: any) => {
        const newValue = e.target.value;
        if (newValue.length <= maxLength) {
            setState(newValue);
        }
    };

    return (
        <div className='custom-input'
            style={
                state !== null && required && state.length < 1 ?
                    {
                        border: '1px solid red',
                        backgroundColor: 'rgba(246, 246, 246, 1)',
                        padding: '12px',
                        borderRadius: '12px',
                        marginBottom: '12px'
                    }
                    :
                    {
                        backgroundColor: 'rgba(246, 246, 246, 1)',
                        padding: '12px',
                        borderRadius: '12px',
                        marginBottom: '12px'
                    }}
        >
            <p style={{ fontSize: '10px', padding: 0, margin: 0 }}>{title}</p>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <input type={type ? type : "text"}
                    required={!!required}
                    style={{
                        border: 'none',
                        outline: 'none',
                        backgroundColor: 'transparent',
                        width: '100%'
                    }}
                    onChange={e => {
                        setState(e.target.value);
                        onChange ? onChange(e) : console.log();
                        if (maxLength) {
                            handleChange(e)
                        }
                    }}
                    onBlur={e => {
                        if (validateChange) {
                            validateChange(e)
                        }
                    }}
                    onKeyDown={(e) => {
                        if (maxLength && state && state.length >= maxLength && e.key !== 'Backspace' && e.key !== 'Delete'
                        ) {
                            e.preventDefault();
                        }
                        onKeyDown ? onKeyDown(e) : console.log();
                    }}
                    ref={ref}
                    value={defaultValue ? defaultValue : ref && ref.current?.value}
                    disabled={disabled}
                    {...rest}
                />
                {!disabled ? (
                     <img 
                        src={closeIcon} 
                        alt="" 
                        style={{ width: '20px', height: '20px', cursor: 'pointer' }} 
                        onClick={() => clearOnClick(ref)}
                    />
                ): null}
            </div>
            {errorText && <span style={{ color: 'red' }}>{errorText}</span>}
        </div>
    )
}

export default InputField