import React, { useContext, useEffect, useRef } from "react";
import { AppContext } from "../../service/AppContext";
import { useParams } from "react-router-dom";
import ReviewItem from "./Heroboard/ReviewItem";
import { ClientContext } from "../../service/ClientContext";
import HeroHeader from "./Heroboard/HeroHeader";
import PoorReview from "./PoorReview";
import LandingReviewButtons from "./LandingClientNew/LandingReviewButtons";

const Heroboard = () => {
  const { slug } = useParams();
  const { allReviews, companyName, setSlug, fetchReviews, totalReviews, company } =
    useContext<any>(ClientContext);

  useEffect(() => {
    setSlug(slug);
  }, []);

  const { setShown, formShown, setFormShown, authData } =
    useContext<any>(AppContext);

  const listRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (totalReviews !== allReviews.length) {
            fetchReviews(slug);
        }
    }, [allReviews]);


  return (
    <div className="heroboard">
      {
        formShown ?
            <PoorReview showForm={setFormShown} showModal={setShown} />
            : <HeroHeader length={totalReviews} />
      }
      <div ref={listRef} className="content-wrapper">
        <div className="hero-content">
          {allReviews &&
            allReviews.map((item: object, index: number) => (
              <ReviewItem data={item} companyName={companyName} key={index + 'item_review_hero'} />
            ))}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          position: "absolute",
          bottom: 0,
          width: "100%",
          paddingBottom: "24px",
        }}
      >
        <div
          className="landing-bottom"
          style={{
            backgroundColor: "white",
            width: "100%",
            padding: "0 0 32px",
            position: "fixed",
            bottom: 0,
          }}
        >
          {slug && company && <LandingReviewButtons
              authData={authData}
              setFormShown={setFormShown}
              navLink={company.google_map_link}
              slug={slug}
              showPrivacyPolicy={false}
          />}
          {/*<div*/}
          {/*  className="text-center"*/}
          {/*  style={{ position: "fixed", bottom: 0, padding: "8px" }}*/}
          {/*>*/}
          {/*  <Link to={app_urls.client.terms} className="grey-link">{t("T&C")}</Link>*/}
          {/*  <span style={{padding: 5}}/>*/}
          {/*  <Link to={app_urls.client.imprint} className="grey-link">{t("Imprint")}</Link>*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
};

export default Heroboard;
