import { apiInstance } from "./api";

export const postReview = async (
  spot_id: number,
  comment: string,
  rating: number,
  email: string,
  name?: string | null,
  lang?: string
) => {
  const response = await apiInstance().post(
    `/api/review`,
    {
      spot_id,
      comment,
      rating,
      name,
      email,
    },
    {
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
        params: {
            lang
        }
    }
  );
  return response.data;
};
