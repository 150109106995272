import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import { copyToClipboard } from "utils/copyToClipboard";
import { apiUrl } from "api/api"

import "./SideBar.scss";
import useBusinessNav from "../../../../../hooks/useBusinessNav";
import {useSpotById} from "../../../../../queries/spot/useSpotById";

const SideBar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const { dashboard_id } = useParams()
  const { sideBarUrl, current} = useBusinessNav()
  const { data: currentSpot} = useSpotById(dashboard_id)

  const logo = require("assets/logo.png");
  const copy = require("assets/copy.png");

  const qr = currentSpot?.data.qr_code;
  const protocol = window.location.protocol;
  const host = window.location.host;
  const linkToCopy = currentSpot
    ? `${protocol}//${host}/${currentSpot.data.spot_slug}`
    : "";

  const [isCopied, setIsCopied] = useState(false);

    return (
    <div id="sidebar">
        <div className="sidebar-top">
            <img src={logo} alt="" style={{width: "100px"}}/>
        </div>
        <div className="sidebar-center">
            {sideBarUrl.map((item, index) => (
                <div
                    key={index + 'nav-tab'}
                    className={
                        item.url === current?.url ? "nav-tab tab-active" : "nav-tab"
                    }
                    onClick={() => navigate('/business' + item.url)}
                >
                    {
                        item.icon && <img src={item.icon} alt="" className="nav-tab-icon"/>
                    }
                    {
                        item.componentIcon && item.componentIcon
                    }
                    <p className="m-0">{item.name}</p>
                </div>
            ))}
            <div className="account-nav-bottom text-center" style={{width: "100%", paddingTop: '20px'}}>
                {currentSpot && qr && currentSpot.data.spot_slug ? (
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <img
                            src={qr ? qr : ""}
                            alt=""
                            style={{width: "59px", height: "59px"}}
                        />
                        <a
                            className="mt-2"
                            style={{textDecoration: "none"}}
                            href={`${apiUrl}/api/spot/qr-download/${currentSpot.data.spot_slug}`}
                            rel="noopener noreferrer"
                        >
                            {t("Download QR code")}
                        </a>
                        {/* <a href="#" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
                            <span>copy link</span>
                        </a> */}
                    </div>
                ) : null}
                <br/>
                {currentSpot && linkToCopy ? (
                    <div style={{position: 'relative', display: 'inline-block'}}>
                        <a
                            href={linkToCopy}
                            style={{
                                textDecoration: "none",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            onClick={(e) => {
                                e.preventDefault();
                                copyToClipboard(linkToCopy);
                                setIsCopied(true);
                                setTimeout(() => setIsCopied(false), 2000);
                            }}
                        >
                            <img src={copy} alt="" className=""/>
                            <span className="text-center">{t("copy link")}</span>
                        </a>
                        {isCopied && <div className="copy-confirmation">{"Copied"}</div>}
                    </div>
                ) : null}
            </div>
        </div>
    </div>
    );
};

export default SideBar;
