import React from 'react'
import { ActiveReview } from './ReviewTable'
import { useTranslation } from "react-i18next";

import styles from './ReviewTable.module.scss'

type ReviewModalContentProps = {
    review: ActiveReview
}

const ReviewModalContent = ({ review }: ReviewModalContentProps): React.ReactElement | null => {
    const { t } = useTranslation();

    const staricon = require("assets/gold-star.png");

    return review ? (
        <div className={styles.reviewTableModalContent}>
            <div className={styles.reviewTableModalContentTop}>
                <div className={styles.reviewTableModalContentDate}>
                    <span>{new Date(review.created_at).toLocaleDateString()}</span>
                </div>
                <div className={styles.reviewTableModalContentName}>
                    <span>{t("Name")}:</span>
                    <span>{review.user_name}</span>
                </div>
                <div className={styles.reviewTableModalContentTitle}><span>{review.review_text}</span></div>
                <div className={styles.reviewTableModalContentRating}>               
                    {review.rating ? (
                        Array.from(Array(review.rating).keys()).map(item => (
                            <img
                                key={item}
                                src={staricon}                                    
                                alt=""                                
                            />
                        ))
                    ): null}                
                </div>
            </div>
          </div>
    ) : null
}

export default ReviewModalContent
