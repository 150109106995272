import * as React from "react";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { findAddresses } from "api/findAddresses";
import { useTranslation } from "react-i18next";
import { debounce } from "@mui/material/utils";
import CustomTextField from "components/CustomTextField";

const AutoCompleteAddress = ({ onSetPlace }: { onSetPlace: (place: any) => void }) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState<any>(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState<any>([]);
  const [place, setPlace] = React.useState<any>(null);
  const loaded = React.useRef(false);

  React.useEffect(() => {
    if (inputValue && inputValue.length > 2) {
      findAddresses(inputValue).then((data) => (data && data.places ? setOptions(data.places) : setOptions([])));
    }
  }, [inputValue]);

  return (
    <Autocomplete
      id="google-map-demo"
      sx={{ width: 368 }}
      getOptionLabel={(option) => (typeof option === "string" ? option : option.formattedAddress)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="No locations"
      onChange={(event: any, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setPlace(newValue);
        setValue(newValue?.displayName.text);
        onSetPlace(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <CustomTextField
          {...params}
          label={t("Start typing the business name")}
          className="select-search"
          fullWidth
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.googleMapsUri}>
            <Grid container alignItems="center">
              <Grid item xs>
                <Box key={option.googleMapsUri} component="span" sx={{ fontWeight: "bold" }}>
                  {option.displayName.text}
                </Box>
                <Typography variant="body2" color="text.secondary">
                  {option.formattedAddress}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};

export default AutoCompleteAddress;
