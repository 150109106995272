import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ClientsRoutes from "./routes/ClientsRoutes";
import BusinessRoutes from "./routes/BusinessRoutes";
import AuthTokenComponent from "./components/authWithToken/AuthTokenComponent";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/*" element={<ClientsRoutes />} />
      <Route path="/business/*" element={<BusinessRoutes />} />
      <Route path="/auth/user" element={<AuthTokenComponent/>} />
    </Routes>
  );
};

export default AppRoutes;
