import React, { createContext, useState, useEffect, ReactNode, useRef } from "react";
import { ServiceFunctions } from "./serviceFunctions";
import { getCompanySpot } from "../api/companyApi";
import {Companies} from "../types/Companies";

interface ClientContextData {
  slug: string;
  setSlug: React.Dispatch<React.SetStateAction<string>>;
  allReviews: object[] | null;
  setAllReviews: React.Dispatch<React.SetStateAction<object[]>>;
  language: string | null;
  setLanguage: React.Dispatch<React.SetStateAction<string | null>>;
  companyId: number | null;
  setCompanyId: React.Dispatch<React.SetStateAction<number | null>>;
  companyName: string | null;
  setCompanyName: React.Dispatch<React.SetStateAction<string | null>>;
  spotLink: string | null;
  setSpotLink: React.Dispatch<React.SetStateAction<string | null>>;
  totalReviews: number | null;
  setTotalReviews: React.Dispatch<React.SetStateAction<number>>;
  fetchReviews: (slug: string, page: number) => Promise<void>;
  reviewsCurrentPage: number;
  setReviewsCurrentPage: any;
  spot_id: number | null;
  company: Companies | null
  fetchedCompany: boolean
}

export const ClientContext = createContext<ClientContextData | null>(null);

interface ClientProviderProps {
  children: ReactNode;
}

export const ClientProvider: React.FC<ClientProviderProps> = ({ children }) => {
  const [allReviews, setAllReviews] = useState<object[]>([]);
  const [language, setLanguage] = useState<string | null>(null);


  const [slug, setSlug] = useState<string>("");
  const [companyId, setCompanyId] = useState<number | null>(null);
  const [spotLink, setSpotLink] = useState<string | null>(null);
  const [companyName, setCompanyName] = useState<string | null>(null)
  const [spot_id, setSpotId] = useState<number | null>(null);
  const [company, setCompany] = useState<Companies | null>(null)
  const [fetchedCompany, setFetchedCompany] = useState<boolean>(false);

  const [totalReviews, setTotalReviews] = useState<number>(0);;
  const [reviewsCurrentPage, setReviewsCurrentPage] = useState<number>(1);
  const reviewsCurrentPageRef = useRef<number>(1);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    const lang = localStorage.getItem("language");
    if (lang) {
      setLanguage(lang);
    }
  }, []);

  const fetchReviews = async (slug: string, page?: number) => {
    if (!hasMore) return;
    const data = await ServiceFunctions.getReviews(slug, reviewsCurrentPageRef.current);
    const currentData = data.data?.reviews || []
    const newData = [...allReviews, ...currentData];
    setAllReviews(newData);
    setTotalReviews(data.data?.total);
    reviewsCurrentPageRef.current = data.data?.current_page + 1;
    setReviewsCurrentPage(data.data?.current_page);
    setHasMore(newData.length < data.data?.total);
  };

  useEffect(() => {
    if (slug) {
      fetchReviews(slug, reviewsCurrentPage);
      getCompanySpot(slug).then((data) => {
        setCompany(data.data)
        setSpotId(data.data?.spot_id);
          setCompanyId(data.data?.company_id);
          setCompanyName(data.data?.company_name);
      }).finally(() => {
        setFetchedCompany(true)
      });
    }
  }, [slug]);

  function ReviewsCurrentPage (page: number) {
    setReviewsCurrentPage(page);
    reviewsCurrentPageRef.current = page;
  }

  return (
    <ClientContext.Provider
      value={{
        slug,
        setSlug,
        allReviews,
        setAllReviews,
        language,
        setLanguage,
        companyId,
        setCompanyId,
        setCompanyName,
        companyName,
        spotLink,
        setSpotLink,
        totalReviews,
        setTotalReviews,
        fetchReviews,
        reviewsCurrentPage,
        setReviewsCurrentPage: ReviewsCurrentPage,
        spot_id,
        company,
        fetchedCompany
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};
