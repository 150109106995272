import React from "react";
import {MenuItem, Select, Switch} from "@mui/material";
import {t} from "i18next";
import'./SelectPlanControl.scss'

type SelectPlanControlProps = {checked: boolean, onChangeChecked: (value: boolean) => void}

function SelectPlanControl ({checked, onChangeChecked}: SelectPlanControlProps) {
    const [currency, setCurrency] = React.useState('eur')


    return <div className="d-flex flex-column align-items-center w-100 mb-4 flex-md-row justify-content-md-between">
        <div className="fw-bolder mb-5 mb-md-0">
            <span>{t('Monthly')}</span>
            <span className="switch-billing-plan-wrapper">
                <Switch checked={checked} onChange={(e) => onChangeChecked(e.target.checked)}/>
            </span>
            <span>{t('Annual')}</span>
        </div>
        <div className="select-currency-wrapper">
            <span className="fw-bolder">{t('Select currency')}</span>
            <Select value={currency} onChange={(e) => setCurrency(e.target.value)}>
                {/*<MenuItem value={'usd'}>USD</MenuItem>*/}
                <MenuItem value={'eur'}>EUR</MenuItem>
            </Select>
        </div>
    </div>
}

export default SelectPlanControl;