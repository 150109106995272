import React, {useContext, useEffect, useMemo, useState} from 'react'
import { useTranslation } from 'react-i18next'
import { AppContext } from "../../../service/AppContext";
import styles from './LandingClientNew.module.scss';

import TopBar from 'components/TopBar'
import LandingStatBlock from 'components/LandingStatBlock'
import BoySvg from "../../../components/svg/Boy";
import GirlSvg from "../../../components/svg/Girl";

import { useMediaQuery } from "react-responsive";
import {useParams} from "react-router-dom";
import { ClientContext } from "../../../service/ClientContext";
import ModalSentReview from "../../../components/modalSentReview/ModalSentReview";
import PoorReview from "../PoorReview";
import CompanyNotFound from "./CompanyNotFound";
import LandingReviewButtons from "./LandingReviewButtons";

const LandingClientNew = () => {
    const clouds = require('assets/sky.png');
    const treeWithGlassImg = require('assets/landing-client/tree.png');
    const glass = require('assets/landing-client/glass.png');
    const leftMsg = require('assets/left-msg.png');
    const rightMsg = require('assets/right-msg.png');

    localStorage.setItem('role', '1');

    const { slug } = useParams();
    const { t } = useTranslation();
    const { shown, setShown, formShown, setFormShown, authData } = useContext<any>(AppContext);
    const { allReviews, companyName, setSlug, totalReviews, fetchReviews, company, fetchedCompany } =  useContext<any>(ClientContext);
    const [navLink, setNavlink] = useState<string | null>(null);

    const [animate, setAnimate] = useState(false);
    const [aniLeftMsg, setAniLeftMsg] = useState(false);
    const [aniRightMsg, setAniRightMsg] = useState(false);

    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

    useEffect(() => {
        const animateTimeoutId = setTimeout(() => {
            setAnimate(true);
        }, 5800);
        const messagesTimeoutId = setTimeout(() => {
            setAniLeftMsg(true);
            setAniRightMsg(true);
        }, 2800);
        return () => {
            clearTimeout(animateTimeoutId);
            clearTimeout(messagesTimeoutId);
        }
    }, []);

    useEffect(() => {
        setSlug(slug);
    }, [slug]);

    useEffect(() => {
        if (company){
            setNavlink(company.google_map_link)
            localStorage.setItem('navlink', company.google_map_link)
        }
    }, [company])

    useEffect(() => {
        if (false === shown) {
            fetchReviews(slug);
        }
    }, [shown])

    const leftMessageClassName = useMemo(() => {
        if (aniLeftMsg && !animate) {
            return styles.landingPageLeftMessage + ' ' + styles.landingPageLeftMessage_AnimateZoomIn;
        } else if (animate) {
            return styles.landingPageLeftMessage + ' ' + styles.landingPageLeftMessage_AnimateBack;
        } else {
            return styles.landingPageLeftMessage;
        }
    }, [animate, aniLeftMsg]);

    const rightMessageClassName = useMemo(() => {
        if (aniLeftMsg && !animate) {
            return styles.landingPageRightMessage + ' ' + styles.landingPageRightMessage_AnimateZoomIn;
        } else if (animate) {
            return styles.landingPageRightMessage + ' ' + styles.landingPageRightMessage_AnimateBack;
        } else {
            return styles.landingPageRightMessage;
        }
    }, [animate, aniRightMsg]);


    return (
        <div className={styles.landingPage}>
            <div className={styles.landingPageTopBarBlock}>
                <TopBar />
            </div>
            {formShown && <PoorReview showForm={setFormShown} showModal={setShown} />}
            {shown && <ModalSentReview />}
            <img className={styles.landingPageClouds} src={clouds} alt='clouds' />

            {fetchedCompany && (company?.is_activated ? <>
                  <div className={styles.landingPageTopBlock}>
                      <div className={styles.landingPageTop}>
                          <h4 className={styles.landingPageTitle}>
                              {t('Leave a review for')} <span className='green-text'>{company.spot_name || companyName || 'Cafe'}</span> <br/>
                              {t('& they will plant a real ')} <br/>
                              {t('tree in your name')}
                          </h4>
                          {!isMobile && allReviews && (
                            <div className={styles.landingPageStatsBlock}>
                                <LandingStatBlock data={allReviews} total={totalReviews} className=''/>
                            </div>
                          )}
                      </div>
                  </div>
                  <div className={styles.landingPageBottom}>
                      <div className={styles.landingPageTreeWithChildren}>
                          <div className={styles.treeBlock}>
                              <div className={styles.treeImgBlock}>
                                  <img className={animate ? styles.treeImgAnimate : styles.treeImg} src={treeWithGlassImg}
                                       alt="tree"/>
                                  <img className={styles.treeGlassImg} src={glass} alt="glass"/>
                              </div>
                              <div className={styles.children}>
                                  <div className={styles.landingPageBoyWrapper}>
                                      <img
                                        className={leftMessageClassName}
                                        src={leftMsg}
                                        alt="left-message"
                                      />
                                      <BoySvg
                                        id=''
                                        className={
                                            animate
                                              ? styles.landingPageBoy_Revers + ' ' + styles.landingPageBoy
                                              : styles.landingPageBoy_SlideIn + ' ' + styles.landingPageBoy
                                        }
                                        width={isMobile ? 83 : 138}
                                        height={isMobile ? 90 : 151}
                                      />
                                  </div>
                                  <div className={styles.landingPageGirlWrapper}>
                                      <img
                                        className={rightMessageClassName}
                                        src={rightMsg}
                                        alt="right-message"
                                      />
                                      <GirlSvg
                                        id=''
                                        className={
                                            animate
                                              ? styles.landingPageGirl_Revers + ' ' + styles.landingPageGirl
                                              : styles.landingPageGirl_SlideIn + ' ' + styles.landingPageGirl
                                        }
                                        width={isMobile ? 55 : 92}
                                        height={isMobile ? 91 : 154}
                                      />
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div style={{paddingBottom: 3}}>
                          {isMobile && allReviews && (
                            <div className={styles.landingPageStatsBlock}>
                                <LandingStatBlock data={allReviews} total={totalReviews} className=''/>
                            </div>
                          )}
                          {slug && <LandingReviewButtons
                              setFormShown={setFormShown}
                              authData={authData}
                              navLink={navLink}
                              slug={slug}
                              />}
                      </div>
                  </div>
              </> :
              <CompanyNotFound
                animate={animate}
                glass={glass}
                isMobile={isMobile}
                leftMessageClassName={leftMessageClassName}
                leftMsg={leftMsg}
                rightMessageClassName={rightMessageClassName}
                rightMsg={rightMsg}
                treeWithGlassImg={treeWithGlassImg}
              />)
            }
        </div>
    )
}




export default LandingClientNew;
