import React, { SVGAttributes } from 'react';

interface PoorIconSvgProps extends SVGAttributes<HTMLOrSVGElement> {
    id?: string;
    className?: string;
    width?: number;
    height?: number;
}

const PoorIconSvg: React.FC<PoorIconSvgProps> = ({ id = '', className = '', width = 86, height = 113, ...props}) => {
    return (
      <svg id={id} className={className} width={width} height={height} viewBox="0 0 86 87" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2785_5598)">
          <path
            d="M43 86.3008C66.7482 86.3008 86 67.049 86 43.3008C86 19.5525 66.7482 0.300781 43 0.300781C19.2518 0.300781 0 19.5525 0 43.3008C0 67.049 19.2518 86.3008 43 86.3008Z"
            fill="#F25674"/>
          <path fillRule="evenodd" clipRule="evenodd"
                d="M44.8914 66.7798C45.1106 66.6747 45.2478 66.4508 45.2419 66.2078C45.1566 62.6799 45.114 60.6349 45.114 60.073V58.4835L45.3799 57.9272C45.6786 57.3025 45.8329 57.0433 48.0205 53.4892C48.8772 52.0974 50.0095 50.1901 50.5367 49.2506C51.1651 48.1309 51.494 46.8681 51.4916 45.5841L51.4605 28.4321C51.4566 26.3299 49.7514 24.6278 47.6493 24.6278L28.8918 24.6278L28.208 24.9634C26.6726 25.717 25.5452 27.4705 25.537 29.1181C25.5338 29.7534 25.5541 29.8357 25.8765 30.5004C26.0651 30.8891 26.2095 31.2131 26.1975 31.2202C26.1855 31.2274 25.8315 31.3848 25.4109 31.5701C23.6041 32.3662 22.5408 33.6314 22.4638 35.077C22.4231 35.8403 22.6019 36.3676 23.2054 37.266C23.4487 37.6282 23.6478 37.9457 23.6478 37.9718C23.6478 37.9978 23.5889 38.0191 23.5169 38.0191C23.4449 38.0191 23.0928 38.171 22.7345 38.3566C21.0978 39.2045 20.1607 40.8139 20.4263 42.321C20.6033 43.3251 21.1323 44.1234 22.3075 45.1602L22.6925 45.4999L22.25 45.9732C21.6793 46.5837 21.333 47.0861 21.1056 47.6335C20.9561 47.9933 20.9228 48.2237 20.9213 48.9101C20.9183 50.2192 21.221 51.0965 21.9372 51.8548C22.4169 52.3627 22.9521 52.6932 23.7085 52.9488L24.3299 53.1588C32.8627 53.2059 37.129 53.24 37.1289 53.2608C37.1286 53.2922 37.0566 53.9078 36.9689 54.6291C36.768 56.2805 36.7461 59.2946 36.9271 60.3909C37.4423 63.5125 39.1025 65.9576 41.2869 66.8122C41.8339 67.0261 42.0136 67.0562 42.8671 67.0758C43.3967 67.088 43.9878 67.0653 44.1806 67.0252C44.3734 66.9853 44.6933 66.8748 44.8914 66.7798ZM62.636 48.2922H55.3685C54.7279 48.2922 54.2086 47.7728 54.2086 47.1322L54.2086 24.162C54.2086 23.5213 54.7279 23.002 55.3685 23.002H62.636C63.2766 23.002 63.7959 23.5213 63.7959 24.162L63.7959 47.1322C63.7959 47.7728 63.2766 48.2922 62.636 48.2922Z"
                fill="white"/>
        </g>
        <defs>
          <clipPath id="clip0_2785_5598">
            <rect width="86" height="86" fill="white" transform="translate(0 0.300781)"/>
          </clipPath>
        </defs>
      </svg>
    );
}

export default PoorIconSvg;
