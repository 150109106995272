import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "service/AppContext";
import { pickPlan } from "api/paymentsApi";
import { useMediaQuery } from "react-responsive";
import Logo from 'components/Logo'

import styles from './SelectBillingPlan.module.scss';
import SelectPlanControl from "./SelectPlanControl";
import {Divider} from "@mui/material";

interface Plan {
    id: number;
    name: string;
    price: string;
    is_recurring: boolean;
    description: string
    billing_period: string | null
}


  const Monthly = ({ item, setPlan, progress, setProgress, isMobile }: any) => {
    const { accessToken } = useContext<any>(AppContext);
    const { t } = useTranslation();

    const spanName = item.name.split('Heroboard')[1]

      return (
      <div className={`d-flex
       flex-column align-items-center justify-content-between
       ${!item.is_recurring && styles.greenCardPlan}
       ${isMobile ? '' : 'ms-3 '}${styles.planMonthly} ${isMobile ? styles.planMonthlyMobile : ''}`} key={item.id}>
          <div className="d-flex flex-column align-items-center">
              <h3 className="text-center fw-bold mt-0">{item.name.includes('Heroboard') ? <>Heroboard<span
                  className={'name-separate'}>{spanName}</span></> : item.name}</h3>
              <h3>
                  {item.is_recurring ? <>
                      <span className={styles.bold}>€{item.price}</span>
                      <span className="fw-bold" style={{opacity: 0.3}}> / {item.billing_period === 'Yearly' ? 'y' : 'mo'} </span>
                  </> : <span>€{item.price}</span>
                  }
              </h3>
              <p className="mb-1">
                  {`+${item.review_price}${t('€ per tree planted')}`}
                  &nbsp;
              </p>
              <p className="mb-1">
                  {`+${item.start_box_price}${t('€ for Start-Up Box')}`}
                  &nbsp;
              </p>
              <hr style={{
                  borderTop: `1px solid ${item.is_recurring ? 'black' : 'white'}`,
                  height: "1px",
                  width: "100%"
              }}/>
              <p className="mb-1 mt-5 text-center" style={{whiteSpace: 'pre-wrap'}}>{
                  item.description.split("\\n").map((value: string) => {
                      return <p className="mb-1">{value}</p>
                  })}
              </p>

          </div>
          <button
              onClick={() => {
                  // setPlan(item.id)
                  if (accessToken) {
                      console.log("Monthly accessToken", accessToken);
                  }
                  pickPlan(item.id, document.location.href, document.location.href).then((data) => {
                      document.location.href = data ? data.payment_link : document.location.href;
                  });
                  // progress && handleSubmit(progress, item, setProgress)
              }}
          >
              {t("Buy")}
          </button>
      </div>
      );
  };

const SelectBillingPlan = ({plans, progress, setProgress}: any) => {
    const {t} = useTranslation();

    const [annualPlans, setAnnualPlans] = React.useState(false)
    const [plan, setPlan] = useState<number | null>(null);

    const isMobile = useMediaQuery({query: '(max-width: 1024px)'});

    const currentPlans = React.useMemo(
        () => plans && [...plans]
            .filter((item: Plan) =>
                item.billing_period === (annualPlans ? 'Yearly' : 'Monthly')
            || item.billing_period === null)
            .sort((a, b) => a.price - b.price),
      [plans, annualPlans])

  // const handleSubmit = (progress: object, item: Plan, setProgress: any) => {
  //     setProgress({ ...progress, tariff_id: item.id });
  // };

  return (
      <div className="container">
          <div
              className={`goals-wrapper mw-100 mt-5 pt-5 mt-lg-0 pt-lg-4 ${isMobile ? styles.billingPlanMobile : ''}`}
              style={{paddingBottom: "80px", marginTop: 0, width: '100vw'}}>
              <div>
                  <Logo/>
              </div>
              <h3 className="text-center fw-bold">
                  {t("PRICING PLANS")}:
              </h3>
              <SelectPlanControl checked={annualPlans} onChangeChecked={setAnnualPlans}/>
              <Divider orientation={'horizontal'} flexItem className="mb-5"/>
              <div className="billing-plans">
                  {currentPlans &&
                      currentPlans.map((item: Plan) =>
                          <Monthly
                              item={item}
                              key={item.id}
                              progress={progress}
                              setPlan={setPlan}
                              plan={plan}
                              setProgress={setProgress}
                              isMobile={isMobile}
                          />
                      )}
              </div>
          </div>
      </div>
  );
};

export default SelectBillingPlan;
