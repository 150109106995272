import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { url } from 'service/config';

function SignIn() {
    const { t } = useTranslation();
    const logo = require('assets/logo.png')
    const googleIcon = require('assets/google-icon.png')

    const [loginUrl, setLoginUrl] = useState<string | null>(null);

    const [roleIds, setRoleIds] = useState<any>(null)
    useEffect(() => {
        fetch(`${url}/api/role`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Something went wrong!');
        })
            .then((data) => { setRoleIds(data.data) })
    }, [])

    const roleId = roleIds ? roleIds.find((item: { name: string; }): any => item.name === 'client').id : null

    localStorage.setItem('role', roleId)

    useEffect(() => {

        fetch(`${url}/api/auth/redirect`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong!');
            })
            .then((data) => { setLoginUrl(data.data.url) })
            .catch((error) => console.error(error));
    }, []);


    return (
        <div className='sign-redirect'>
            <img src={logo} alt="" style={{ maxWidth: '100px' }} />

            {
                loginUrl && (
                    <a className='google-sign' href={loginUrl}>
                        <img src={googleIcon} alt="" />
                        <span>{t("Continue with Google")}</span>
                    </a>
                )
            }

            <p>
                <Link to='/terms' className="grey-link">{t("By continuing you agree to our Terms and Privacy Policy.")}</Link>
            </p>            

            <a className='link-back' style={{cursor: "pointer"}} onClick={() => {
                window.history.back()
            }}>{t("Back")}</a>
        </div>
    );
}

export default SignIn;
