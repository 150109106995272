import {useQuery} from "@tanstack/react-query";
import queryKeys from "../queryKeys";
import {apiInstance} from "../../api/api";
import {SpotResponse} from "../../types/Spot";

export const useSpotList = (page: number, company_id?: string) => {
    return useQuery({
        queryKey: [queryKeys.spots, page],
        queryFn: async () => {
            const response = await apiInstance().get<SpotResponse>("api/spots", {
                params: {
                    page,
                    company_id
                }
            });
            return response.data;
        }
    })
}