import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useParams } from 'react-router-dom'
import TopBar from 'components/TopBar'
import LandingStatBlock from 'components/LandingStatBlock'
import ModalSentReview from 'components/modalSentReview/ModalSentReview'
import { AppContext } from 'service/AppContext'
import BoySvg from 'components/svg/Boy';
import GirlSvg from 'components/svg/Girl';
import { ClientContext } from 'service/ClientContext'
import { getCompanySpot } from "api/companyApi";
import PoorReview from './PoorReview';

const LandingClient = () => {
    const { t } = useTranslation();
    const { shown, setShown, formShown, setFormShown, authData } = useContext<any>(AppContext)
    const { allReviews, companyName, setSlug, totalReviews, fetchReviews } =  useContext<any>(ClientContext);
    const navigate = useNavigate();
    const { slug } = useParams();

    const clouds = require('assets/sky.png');
    const stones = require('assets/stones.png');
    const vector = require('assets/vector.png');
    const tree = require('assets/landing-client/tree.png');
    const leftMsg = require('assets/left-msg.png');
    const rightMsg = require('assets/right-msg.png');

    const [animate, setAnimate] = useState(false);

    const [aniLeftMsg, setAniLeftMsg] = useState(false);
    const [aniRightMsg, setAniRightMsg] = useState(false);
    const isMobile = useMediaQuery({ query: '(max-width: 585px)' })

    const isHeightThan680 = useMediaQuery({ maxHeight: 670 });
    console.log("isMobile", isMobile)

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setAnimate(true);
        }, 5800);
        return () => clearTimeout(timeoutId);
    }, []);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setAniLeftMsg(true);
        }, 2800);
        return () => clearTimeout(timeoutId);
    }, []);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setAniRightMsg(true);
        }, 3000);
        return () => clearTimeout(timeoutId);
    }, []);

    useEffect(() => {
        setSlug(slug);
    }, [slug]);

    const { navlink, setNavlink } = useContext<any>(AppContext)
    useEffect(() => {
        if (slug){
            getCompanySpot(slug).then((data) => {
                setNavlink(data.data.google_map_link)
                localStorage.setItem('navlink', data.data.google_map_link)                
                // ChIJLXjs6n9OK0QRueoPrT700sg
            });
            // localStorage.setItem('navlink', "https://www.google.com/maps/place/ANRO+IT/@34.681045,33.04125,17z/data=!4m8!3m7!1s0x14e733c658b25fe1:0x4cab5b8b453a362b!8m2!3d34.681045!4d33.04125!9m1!1b1!16s%2Fg%2F11t40sfkr2?entry=ttu")
            // setNavlink("https://www.google.com/maps/place/ANRO+IT/@34.681045,33.04125,17z/data=!4m8!3m7!1s0x14e733c658b25fe1:0x4cab5b8b453a362b!8m2!3d34.681045!4d33.04125!9m1!1b1!16s%2Fg%2F11t40sfkr2?entry=ttu")
        }
    }, [slug])

    const negativeReview = localStorage.getItem('review')

    // useEffect(() => {
    //     negativeReview ?
    //         setTimeout(() => {
    //             setFormShown(true)
    //         }, 1000)
    //         :
    //         console.log()
    // }, [])

    useEffect(() => {
        if (false === shown) {
            fetchReviews(slug);
        }
    }, [shown])

    return (
        <div className='landing-client'>
            <TopBar />
            {
                formShown ?
                    <PoorReview showForm={setFormShown} showModal={setShown} />
                    : null
            }
            {shown ? <ModalSentReview /> : null}
            {isMobile ?  (<div className="title_mobile">
                    <h4 className='cta-headline'>
                    {t('Leave a review for')} <span className='green-text'>{companyName}</span> <br />
                    {t('& they will plant a real')} <br />
                    {t('tree in your name')}
                    </h4>
                </div>) : (<div className="title desktop">
                <h4 className='cta-headline'>
                    {t('Leave a review for')} <span className='green-text'>{companyName}</span> <br />
                    {t('& they will plant a real')} <br />
                    {t('tree in your name')}
                </h4>
            </div>)}  

            <div className={isMobile ?"landing-center-mobile": "landing-center" }>               
                <img className="anima-clouds" src={clouds} alt='clouds' />
                {!isMobile && <div className= "hero-board">
                    {allReviews && <LandingStatBlock data={allReviews} total={totalReviews} className='hero-board'/>}
                </div>}
                <div className={isMobile ? isHeightThan680 ? "anima-main-mobile-short":"anima-main-mobile": "anima-main"}>
                    <div>
                        <img
                            className={
                                aniLeftMsg && !animate ?
                                    'message-left animate-zoom' :
                                    animate ? 'message-left message-left-back hide' :
                                        'message-left' 
                            }
                            src={leftMsg} alt=""
                        />
                        
                        <BoySvg id='hero-boy'  className={animate ? 'reverse-boy' : 'slidein-boy'}  width={120} />                                                                                                                
                    </div>
                    <div className="tree-block">
                        <img id='hero-tree' className={animate ? 'animate-zoom' : ''} src={tree} alt="tree" />
                        <img src={stones} alt="stones" className='stones' />
                    </div>
                    <div>
                        <img
                            className={
                                aniRightMsg && !animate ?
                                    'message-right animate-zoom' :
                                    animate ? 'message-right message-right-back hide' :
                                        'message-right'
                            }
                            src={rightMsg}
                            alt="message"
                        />
                        <GirlSvg id='hero-girl' className={ animate ? 'reverse-girl' : 'slidein-girl'}
                            width={83} />
                    </div>
                </div>
            </div>
            <div className={isMobile? "landing-bottom-mobile" : "landing-bottom"}>
                {isMobile && allReviews &&  <div className="hero-board-mobile">
                    <LandingStatBlock data={allReviews} total={totalReviews} className='hero-board'/>
                </div>}
                <div className='click-btn-title'>
                    <p className='text-center fw-bold' style={{ fontSize: '8px !important'}}>{t('Click the button and make an impact')}</p>
                </div>
                <div className="vote-buttons">
                    <img className="vector" src={vector} alt='vector' />

                    <div className='poor-icon' onClick={() => {
                        // localStorage.setItem('review', 'negative');
                        // authData ?
                            setFormShown(true);
                            // :
                            // navigate('/sign')
                    }}>&nbsp;</div>
                    {
                        authData ?
                            <a href={navlink || ""} onClick={() => {
                                console.log('navlink', navlink)
                            }}>
                                <div className="good-icon">&nbsp;</div>
                            </a>
                            :
                            <div className='good-icon' onClick={() => {
                                localStorage.setItem('review', 'positive');
                                navigate('/sign');
                            }
                            } />
                    }
                </div>
                {isMobile ? <div className='terms_mob'>
                    <a href='#' className='grey-link'>T&C and Imprint</a>
                </div>: <div className='text-center terms'>
                    <a href='#' className='grey-link'>T&C and Imprint</a>
                </div> }
            </div>
        </div>
    )
}

export default LandingClient