import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "app/hooks";
import { logoutAsync } from "features/business/companySlice";
import {activeIndex} from "../BusinessOnboarding";

const ProgresBar = ({ stage, route, setStage, step, setStep }: any) => {
  const { t } = useTranslation();
  const exit = require("assets/exit.png");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <div className="create-progress">
      {route &&
        route.map((item: string, index: number) => (
          <p
              key={index}
            className={activeIndex[index].includes(step) ? "route-item route-active" : "route-item"}
            onClick={() => {
              console.log(index);
              const stageIndex = route.findIndex((x: string) => x === stage);
              if (!(item && stage && item === stage) && stageIndex > index) {
                setStep(step - 1);
                setStage(item);
              }
            }}
          >
            {item}
          </p>
        ))}
      <div
        className="logout"
        onClick={async () => {
          await dispatch(logoutAsync());
          navigate("/business/signin");
        }}
      >
        <img src={exit} alt="logout" />
        <span>{t("Log out")}</span>
      </div>
    </div>
  );
};

export default ProgresBar;
